import './sidebar.css';
import {
  LineStyle,
  // Timeline,
  TrendingUp,
  PermIdentity,
  // BarChart,
  // Storefront,
  AttachMoney,

  // MailOutline,
  // DynamicFeed,
  // ChatBubbleOutline,
  // WorkOutline,
  // Report,
} from '@material-ui/icons';

import { Link } from 'react-router-dom';

const dummy = [
  {
    link: '/admin/home',
    style: <LineStyle className='sidebarIcon' />,
    name: 'Home',
  },
  {
    link: '/admin/mainPage',
    style: <PermIdentity className='sidebarIcon' />,
    name: 'CMS',
  },
  {
    link: '/admin/updatedLanding',
    style: <PermIdentity className='sidebarIcon' />,
    name: 'Landing Page',
  },
  {
    link: '/admin/users',
    style: <TrendingUp className='sidebarIcon' />,
    name: 'Manage Pricing',
  },
  {
    link: '/admin/coupon',
    style: <AttachMoney className='sidebarIcon' />,
    name: ' Coupons',
  },
];

export default function Sidebar() {
  return (
    <div className='sidebar'>
      <div className='sidebarWrapper'>
        <div className='sidebarMenu'>
          <h3 className='sidebarTitle'>MeSHeDInC</h3>
          <ul className='sidebarList'>
            {dummy.map((val, count) => (
              <Link to={val.link} className='link'>
                <li
                  className={`sidebarListItem ${
                    window.location.pathname === val.link ? 'active' : null
                  } `}
                >
                  {val.style}
                  {val.name}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
