import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Card,
  CardContent,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { Link } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as ROUTES from '../../constants/Routes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery, useMutation, gql } from '@apollo/client';
import { UPLOAD_FILE } from "../../graphql/Mutations";
import toast from "react-hot-toast";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Import your existing assets
import banner from '../../assets/laptop-3.png';
import hostimage from '../../assets/hosts.png';
import Custom from '../../assets/Custom1.png';
import VIP from '../../assets/VIP1.png';
import Virtual from '../../assets/Virtual1.png';
import heroVideo from '../../assets/hero-video.mp4';

import video1 from '../../assets/video-1.mp4';
import video2 from '../../assets/video-2.mp4';
import video3 from '../../assets/video-3.mp4';

const ADMIN_GETUPDATEDHEADER = gql`
  query GetUpdatedHeaders {
    getUpdatedHeaders {
      heroTitle
      heroSubtitle
      heroButtonText
      whyChooseTitle
      whyChooseSubtitle
      customEventTitle
      customEventDesc
      virtualAttendeeTitle
      virtualAttendeeDesc
      advancedFeedTitle
      advancedFeedDesc
      breakFreeTitle
      breakFreeSubtitle
      breakFreeFeatures
      virtualExpTitle
      virtualExpDesc
      virtualExpDesc2
      hostControlsTitle
      hostControlsDesc
      moderationTitle
      moderationDesc
      roomControlTitle
      roomControlDesc
      analyticsTitle
      analyticsDesc
      permissionsTitle
      permissionsDesc
      testimonialTitle
      testimonialSubtitle
      testimonial1Quote
      testimonial1Author
      testimonial1Role
      testimonial1Company
      testimonial1CompanyLogo
      testimonial2Quote
      testimonial2Author
      testimonial2Role
      testimonial2Company
      testimonial2CompanyLogo
      testimonial3Quote
      testimonial3Author
      testimonial3Role
      testimonial3Company
      testimonial3CompanyLogo
      testimonial4Quote
      testimonial4Author
      testimonial4Role
      testimonial4Company
      testimonial4CompanyLogo
      helpfulResourcesTitle
      gettingStartedTitle
      gettingStartedDesc
      bestPracticesTitle
      bestPracticesDesc
      apiDocsTitle
      apiDocsDesc
      useCasesTitle
      useCasesSubtitle
      communityTitle
      communitySubtitle
      communityDesc
      pricingTitle
      pricingSubtitle
      personalPlanTitle
      personalPlanDesc
      personalPlanPrice
      personalPlanDuration
      personalPlanFeatures
      proPlanTitle
      proPlanDesc
      proPlanPrice
      proPlanDuration
      proPlanFeatures
      faqTitle
      faqSubtitle
      faqQuestion1
      faqAnswer1
      faqQuestion2
      faqAnswer2
      faqQuestion3
      faqAnswer3
      faqQuestion4
      faqAnswer4
      ctaTitle
      ctaSubtitle
      ctaButtonText
    }
  }
`;

const ADMIN_UPDATED_LANDING = gql`
  mutation UpdateHeaders(
    $heroTitle: String!
    $heroSubtitle: String!
    $heroButtonText: String!
    $whyChooseTitle: String!
    $whyChooseSubtitle: String!
    $customEventTitle: String!
    $customEventDesc: String!
    $virtualAttendeeTitle: String!
    $virtualAttendeeDesc: String!
    $advancedFeedTitle: String!
    $advancedFeedDesc: String!
    $breakFreeTitle: String!
    $breakFreeSubtitle: String!
    $breakFreeFeatures: [String!]!
    $virtualExpTitle: String!
    $virtualExpDesc: String!
    $virtualExpDesc2: String!
    $hostControlsTitle: String!
    $hostControlsDesc: String!
    $moderationTitle: String!
    $moderationDesc: String!
    $roomControlTitle: String!
    $roomControlDesc: String!
    $analyticsTitle: String!
    $analyticsDesc: String!
    $permissionsTitle: String!
    $permissionsDesc: String!
    $testimonialTitle: String!
    $testimonialSubtitle: String!
    $helpfulResourcesTitle: String!
    $gettingStartedTitle: String!
    $gettingStartedDesc: String!
    $bestPracticesTitle: String!
    $bestPracticesDesc: String!
    $apiDocsTitle: String!
    $apiDocsDesc: String!
    $useCasesTitle: String!
    $useCasesSubtitle: String!
    $communityTitle: String!
    $communitySubtitle: String!
    $communityDesc: String!
    $pricingTitle: String!
    $pricingSubtitle: String!
    $personalPlanTitle: String!
    $personalPlanDesc: String!
    $personalPlanPrice: String!
    $personalPlanDuration: String!
    $personalPlanFeatures: [String!]!
    $proPlanTitle: String!
    $proPlanDesc: String!
    $proPlanPrice: String!
    $proPlanDuration: String!
    $proPlanFeatures: [String!]!
    $faqTitle: String!
    $faqSubtitle: String!
    $faqQuestion1: String!
    $faqAnswer1: String!
    $faqQuestion2: String!
    $faqAnswer2: String!
    $faqQuestion3: String!
    $faqAnswer3: String!
    $faqQuestion4: String!
    $faqAnswer4: String!
    $ctaTitle: String!
    $ctaSubtitle: String!
    $ctaButtonText: String!
  ) {
    updatedLandingPage(
      heroTitle: $heroTitle
      heroSubtitle: $heroSubtitle
      heroButtonText: $heroButtonText
      whyChooseTitle: $whyChooseTitle
      whyChooseSubtitle: $whyChooseSubtitle
      customEventTitle: $customEventTitle
      customEventDesc: $customEventDesc
      virtualAttendeeTitle: $virtualAttendeeTitle
      virtualAttendeeDesc: $virtualAttendeeDesc
      advancedFeedTitle: $advancedFeedTitle
      advancedFeedDesc: $advancedFeedDesc
      breakFreeTitle: $breakFreeTitle
      breakFreeSubtitle: $breakFreeSubtitle
      breakFreeFeatures: $breakFreeFeatures
      virtualExpTitle: $virtualExpTitle
      virtualExpDesc: $virtualExpDesc
      virtualExpDesc2: $virtualExpDesc2
      hostControlsTitle: $hostControlsTitle
      hostControlsDesc: $hostControlsDesc
      moderationTitle: $moderationTitle
      moderationDesc: $moderationDesc
      roomControlTitle: $roomControlTitle
      roomControlDesc: $roomControlDesc
      analyticsTitle: $analyticsTitle
      analyticsDesc: $analyticsDesc
      permissionsTitle: $permissionsTitle
      permissionsDesc: $permissionsDesc
      testimonialTitle: $testimonialTitle
      testimonialSubtitle: $testimonialSubtitle
      helpfulResourcesTitle: $helpfulResourcesTitle
      gettingStartedTitle: $gettingStartedTitle
      gettingStartedDesc: $gettingStartedDesc
      bestPracticesTitle: $bestPracticesTitle
      bestPracticesDesc: $bestPracticesDesc
      apiDocsTitle: $apiDocsTitle
      apiDocsDesc: $apiDocsDesc
      useCasesTitle: $useCasesTitle
      useCasesSubtitle: $useCasesSubtitle
      communityTitle: $communityTitle
      communitySubtitle: $communitySubtitle
      communityDesc: $communityDesc
      pricingTitle: $pricingTitle
      pricingSubtitle: $pricingSubtitle
      personalPlanTitle: $personalPlanTitle
      personalPlanDesc: $personalPlanDesc
      personalPlanPrice: $personalPlanPrice
      personalPlanDuration: $personalPlanDuration
      personalPlanFeatures: $personalPlanFeatures
      proPlanTitle: $proPlanTitle
      proPlanDesc: $proPlanDesc
      proPlanPrice: $proPlanPrice
      proPlanDuration: $proPlanDuration
      proPlanFeatures: $proPlanFeatures
      faqTitle: $faqTitle
      faqSubtitle: $faqSubtitle
      faqQuestion1: $faqQuestion1
      faqAnswer1: $faqAnswer1
      faqQuestion2: $faqQuestion2
      faqAnswer2: $faqAnswer2
      faqQuestion3: $faqQuestion3
      faqAnswer3: $faqAnswer3
      faqQuestion4: $faqQuestion4
      faqAnswer4: $faqAnswer4
      ctaTitle: $ctaTitle
      ctaSubtitle: $ctaSubtitle
      ctaButtonText: $ctaButtonText
    ) {
      heroTitle
      heroSubtitle
      heroButtonText
      whyChooseTitle
      whyChooseSubtitle
      customEventTitle
      customEventDesc
      virtualAttendeeTitle
      virtualAttendeeDesc
      advancedFeedTitle
      advancedFeedDesc
      breakFreeTitle
      breakFreeSubtitle
      breakFreeFeatures
      virtualExpTitle
      virtualExpDesc
      virtualExpDesc2
      hostControlsTitle
      hostControlsDesc
      moderationTitle
      moderationDesc
      roomControlTitle
      roomControlDesc
      analyticsTitle
      analyticsDesc
      permissionsTitle
      permissionsDesc
      testimonialTitle
      testimonialSubtitle
      helpfulResourcesTitle
      gettingStartedTitle
      gettingStartedDesc
      bestPracticesTitle
      bestPracticesDesc
      apiDocsTitle
      apiDocsDesc
      useCasesTitle
      useCasesSubtitle
      communityTitle
      communitySubtitle
      communityDesc
      pricingTitle
      pricingSubtitle
      personalPlanTitle
      personalPlanDesc
      personalPlanPrice
      personalPlanDuration
      personalPlanFeatures
      proPlanTitle
      proPlanDesc
      proPlanPrice
      proPlanDuration
      proPlanFeatures
      faqTitle
      faqSubtitle
      faqQuestion1
      faqAnswer1
      faqQuestion2
      faqAnswer2
      faqQuestion3
      faqAnswer3
      faqQuestion4
      faqAnswer4
      ctaTitle
      ctaSubtitle
      ctaButtonText
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  heroSection: {
    background: '#f8f9fa',
    padding: theme.spacing(16, 10, 8),
    color: '#2D8CFF',
  },
  heroVideoSection: {
    background: 'linear-gradient(45deg, #8DC0FF 0%, #2D8CFF 100%)',
    padding: theme.spacing(10, 0),
    color: '#fff',
  },
  mainHeading: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  subHeading: {
    marginBottom: theme.spacing(4),
    opacity: 0.9,
  },
  heroButton: {
    backgroundColor: '#fff',
    color: '#000',
    padding: theme.spacing(1.5, 4),
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 600,
    transition: 'all 0.6s ease',
    '&:hover': {
      backgroundColor: '#2D8CFF',
      color: '#fff',
      transform: 'translateY(-2px)',
    },
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    color: '#2D8CFF',
    padding: theme.spacing(1.5, 4),
    borderRadius: 8,
    textTransform: 'none',
    fontWeight: 600,
    border: '2px solid #2D8CFF',
    transition: 'all 0.6s ease',
    '&:hover': {
      backgroundColor: '#2D8CFF',
      color: '#fff',
      transform: 'translateY(-2px)',
    },
  },
  trustedBy: {
    padding: theme.spacing(4, 0),
    opacity: 0.7,
  },
  featureCard: {
    visibility: 'visible',
    opacity: 1,
    height: '100%',
    borderRadius: 16,
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    transition: 'all 0.6s ease',
    background: '#fff',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
    },
  },
  featureIcon: {
    width: 60,
    height: 60,
    marginBottom: theme.spacing(2),
  },
  statsSection: {
    background: '#f8f9fa',
    padding: theme.spacing(8, 0),
  },
  statValue: {
    fontWeight: 700,
    color: '#2D8CFF',
  },
  useCaseSection: {
    padding: theme.spacing(8, 0),
  },
  useCaseCard: {
    visibility: 'visible',
    opacity: 1,
    height: '100%',
    borderRadius: 16,
    overflow: 'hidden',
    position: 'relative',
    background: '#fff',
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    transition: 'all 0.6s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
    },
  },
  useCaseImage: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
  },
  testimonialSection: {
    background: 'linear-gradient(45deg, #8DC0FF 0%, #2D8CFF 100%)',
    padding: theme.spacing(10, 0),
    color: '#fff',
    position: 'relative',
    overflow: 'hidden',
  },
  testimonialCard: {
    visibility: 'visible',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: 16,
    padding: theme.spacing(4),
    color: '#fff',
    height: '100%',
    transition: 'all 0.6s ease',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: 'rgba(141,192,255,0.15)',
      border: '1px solid rgba(45,140,255,0.2)',
    },
  },
  testimonialAvatar: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
    border: '2px solid #fff',
  },
  testimonialCompanyLogo: {
    height: 24,
    marginTop: theme.spacing(2),
    opacity: 0.8,
  },
  testimonialRating: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& svg': {
      color: '#FFD700',
      marginRight: theme.spacing(0.5),
    },
  },
  testimonialQuote: {
    position: 'relative',
    '&::before': {
      content: '"""',
      fontSize: '4rem',
      position: 'absolute',
      top: -20,
      left: -10,
      opacity: 0.2,
    },
  },
  integrationSection: {
    padding: theme.spacing(8, 0),
  },
  integrationIcon: {
    width: 40,
    height: 40,
    margin: theme.spacing(1),
  },
  ctaSection: {
    padding: theme.spacing(10, 0),
    background: 'linear-gradient(45deg, #f8f9fa 0%, #fff 100%)',
  },
  pricingSection: {
    padding: theme.spacing(10, 0),
    background: '#fff',
  },
  pricingCard: {
    height: '100%',
    borderRadius: 16,
    background: '#fff',
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    transition: 'all 0.6s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
    },
  },
  pricingHeader: {
    padding: theme.spacing(3),
    background: 'linear-gradient(45deg, #8DC0FF 0%, #2D8CFF 100%)',
    color: '#fff',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  pricingContent: {
    padding: theme.spacing(3),
  },
  pricingFeature: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  checkIcon: {
    color: '#2D8CFF',
    marginRight: theme.spacing(1),
  },
  priceTag: {
    fontSize: '2.5rem',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    color: '#2D8CFF',
  },
  pricingButton: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  faqSection: {
    padding: theme.spacing(10, 0),
    background: '#fff',
  },
  accordion: {
    background: '#fff',
    borderRadius: '16px !important',
    marginBottom: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: `${theme.spacing(2)}px 0`,
    },
    transition: 'all 0.6s ease',
    '&:hover': {
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
    },
  },
  accordionSummary: {
    borderRadius: 16,
    transition: 'all 0.6s ease',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
    '& .MuiAccordionSummary-content': {
      margin: `${theme.spacing(2)}px 0`,
    },
  },
  accordionDetails: {
    padding: theme.spacing(2, 3, 3, 3),
    color: theme.palette.text.secondary,
  },
  expandIcon: {
    color: '#2D8CFF',
    transition: 'transform 0.6s ease',
    '&.Mui-expanded': {
      transform: 'rotate(180deg)',
    },
  },
  faqContainer: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  communitySection: {
    padding: theme.spacing(8, 0),
    background: '#fff',
  },
  socialIcon: {
    width: 40,
    height: 40,
    margin: theme.spacing(1),
    transition: 'transform 0.6s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  socialBox: {
    visibility: 'visible',
    opacity: 1,
    padding: theme.spacing(2),
    borderRadius: 16,
    transition: 'all 0.6s ease',
    background: '#fff',
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
      background: 'linear-gradient(45deg, rgba(141,192,255,0.1) 0%, rgba(45,140,255,0.1) 100%)',
    },
  },
  logoSlider: {
    overflow: 'hidden',
    background: '#fff',
    padding: theme.spacing(4, 0),
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '200px',
      height: '100%',
      top: 0,
      zIndex: 2,
    },
    '&::before': {
      left: 0,
      background: 'linear-gradient(to right, #fff, transparent)',
    },
    '&::after': {
      right: 0,
      background: 'linear-gradient(to left, #fff, transparent)',
    },
  },
  logoSlide: {
    display: 'flex',
    animation: '$slide 30s linear infinite',
    '& img': {
      height: '50px',
      margin: '0 50px',
      objectFit: 'contain',
      filter: 'grayscale(100%)',
      opacity: 0.7,
      transition: 'all 0.6s ease',
      '&:hover': {
        filter: 'grayscale(0%)',
        opacity: 1,
      },
    },
  },
  '@keyframes slide': {
    from: {
      transform: 'translateX(0)',
    },
    to: {
      transform: 'translateX(-50%)',
    },
  },
  heroVideo: {
    width: '100%',
    height: '600px',
    position: 'relative',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  animatedElement: {
    visibility: 'visible !important',
    opacity: 1,
  },
  customCard: {
    visibility: 'visible',
    opacity: 1,
    height: '100%',
    padding: theme.spacing(4),
    borderRadius: '16px',
    border: '1px solid rgba(45,140,255,0.1)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    transition: 'all 0.6s ease',
    background: '#fff',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
      background: 'linear-gradient(45deg, rgba(141,192,255,0.1) 0%, rgba(45,140,255,0.1) 100%)',
      borderColor: 'rgba(45,140,255,0.2)',
    },
  },
  customCardContent: {
    padding: theme.spacing(3),
  },
  customCardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  customUseCaseCard: {
    visibility: 'visible',
    opacity: 1,
    height: '100%',
    borderRadius: '16px',
    border: '1px solid rgba(45,140,255,0.1)',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
    transition: 'all 0.6s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 30px rgba(45,140,255,0.15)',
      background: 'linear-gradient(45deg, rgba(141,192,255,0.1) 0%, rgba(45,140,255,0.1) 100%)',
      borderColor: 'rgba(45,140,255,0.2)',
    },
  },
}));

// Add this component after the imports and before the UpdatedLandingAdmin component
const EditableInput = ({ edit, value, defaultValue, onChange, style = {}, multiline = false }) => {
  return edit ? (
    <input
      style={{
        fontSize: style.fontSize || "18px",
        fontFamily: "Inter",
        fontWeight: style.fontWeight || "normal",
        color: "black",
        width: "100%",
        padding: "8px",
        border: "1px solid #ddd",
        borderRadius: "4px",
        ...style
      }}
      placeholder={defaultValue}
      value={value ? value : value === null ? defaultValue : ""}
      onChange={(event) => onChange(event.target.value)}
      {...(multiline ? { rows: 4 } : {})}
    />
  ) : null;
};

const UpdatedLandingAdmin = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { loading, error, data, refetch } = useQuery(ADMIN_GETUPDATEDHEADER);
  console.log("🚀 ~ UpdatedLandingAdmin ~ error:", data)
  const [edit, setEdit] = useState(false);
  
  // Add state variables for all the fields
  const [heroTitle, setHeroTitle] = useState(null);
  const [heroSubtitle, setHeroSubtitle] = useState(null);
  const [heroButtonText, setHeroButtonText] = useState(null);
  const [whyChooseTitle, setWhyChooseTitle] = useState(null);
  const [whyChooseSubtitle, setWhyChooseSubtitle] = useState(null);
  const [customEventTitle, setCustomEventTitle] = useState(null);
  const [customEventDesc, setCustomEventDesc] = useState(null);
  const [virtualAttendeeTitle, setVirtualAttendeeTitle] = useState(null);
  const [virtualAttendeeDesc, setVirtualAttendeeDesc] = useState(null);
  const [advancedFeedTitle, setAdvancedFeedTitle] = useState(null);
  const [advancedFeedDesc, setAdvancedFeedDesc] = useState(null);
  const [breakFreeTitle, setBreakFreeTitle] = useState(null);
  const [breakFreeSubtitle, setBreakFreeSubtitle] = useState(null);
  const [breakFreeFeatures, setBreakFreeFeatures] = useState(null);
  const [virtualExpTitle, setVirtualExpTitle] = useState(null);
  const [virtualExpDesc, setVirtualExpDesc] = useState(null);
  const [virtualExpDesc2, setVirtualExpDesc2] = useState(null);
  const [hostControlsTitle, setHostControlsTitle] = useState(null);
  const [hostControlsDesc, setHostControlsDesc] = useState(null);
  const [moderationTitle, setModerationTitle] = useState(null);
  const [moderationDesc, setModerationDesc] = useState(null);
  const [roomControlTitle, setRoomControlTitle] = useState(null);
  const [roomControlDesc, setRoomControlDesc] = useState(null);
  const [analyticsTitle, setAnalyticsTitle] = useState(null);
  const [analyticsDesc, setAnalyticsDesc] = useState(null);
  const [permissionsTitle, setPermissionsTitle] = useState(null);
  const [permissionsDesc, setPermissionsDesc] = useState(null);
  const [testimonialTitle, setTestimonialTitle] = useState(null);
  const [testimonialSubtitle, setTestimonialSubtitle] = useState(null);
  const [helpfulResourcesTitle, setHelpfulResourcesTitle] = useState(null);
  const [gettingStartedTitle, setGettingStartedTitle] = useState(null);
  const [gettingStartedDesc, setGettingStartedDesc] = useState(null);
  const [bestPracticesTitle, setBestPracticesTitle] = useState(null);
  const [bestPracticesDesc, setBestPracticesDesc] = useState(null);
  const [apiDocsTitle, setApiDocsTitle] = useState(null);
  const [apiDocsDesc, setApiDocsDesc] = useState(null);
  const [useCasesTitle, setUseCasesTitle] = useState(null);
  const [useCasesSubtitle, setUseCasesSubtitle] = useState(null);
  const [communityTitle, setCommunityTitle] = useState(null);
  const [communitySubtitle, setCommunitySubtitle] = useState(null);
  const [communityDesc, setCommunityDesc] = useState(null);
  const [pricingTitle, setPricingTitle] = useState(null);
  const [pricingSubtitle, setPricingSubtitle] = useState(null);
  const [personalPlanTitle, setPersonalPlanTitle] = useState(null);
  const [personalPlanDesc, setPersonalPlanDesc] = useState(null);
  const [personalPlanPrice, setPersonalPlanPrice] = useState(null);
  const [personalPlanDuration, setPersonalPlanDuration] = useState(null);
  const [personalPlanFeatures, setPersonalPlanFeatures] = useState(null);
  const [proPlanTitle, setProPlanTitle] = useState(null);
  const [proPlanDesc, setProPlanDesc] = useState(null);
  const [proPlanPrice, setProPlanPrice] = useState(null);
  const [proPlanDuration, setProPlanDuration] = useState(null);
  const [proPlanFeatures, setProPlanFeatures] = useState(null);
  const [faqTitle, setFaqTitle] = useState(null);
  const [faqSubtitle, setFaqSubtitle] = useState(null);
  const [faqQuestion1, setFaqQuestion1] = useState(null);
  const [faqAnswer1, setFaqAnswer1] = useState(null);
  const [faqQuestion2, setFaqQuestion2] = useState(null);
  const [faqAnswer2, setFaqAnswer2] = useState(null);
  const [faqQuestion3, setFaqQuestion3] = useState(null);
  const [faqAnswer3, setFaqAnswer3] = useState(null);
  const [faqQuestion4, setFaqQuestion4] = useState(null);
  const [faqAnswer4, setFaqAnswer4] = useState(null);
  const [ctaTitle, setCtaTitle] = useState(null);
  const [ctaSubtitle, setCtaSubtitle] = useState(null);
  const [ctaButtonText, setCtaButtonText] = useState(null);

  // Add these state variables after the existing useState declarations
  const [testimonial1Quote, setTestimonial1Quote] = useState(null);
  const [testimonial1Author, setTestimonial1Author] = useState(null);
  const [testimonial1Role, setTestimonial1Role] = useState(null);
  const [testimonial1Company, setTestimonial1Company] = useState(null);
  const [testimonial1CompanyLogo, setTestimonial1CompanyLogo] = useState(null);

  const [testimonial2Quote, setTestimonial2Quote] = useState(null);
  const [testimonial2Author, setTestimonial2Author] = useState(null);
  const [testimonial2Role, setTestimonial2Role] = useState(null);
  const [testimonial2Company, setTestimonial2Company] = useState(null);
  const [testimonial2CompanyLogo, setTestimonial2CompanyLogo] = useState(null);

  const [testimonial3Quote, setTestimonial3Quote] = useState(null);
  const [testimonial3Author, setTestimonial3Author] = useState(null);
  const [testimonial3Role, setTestimonial3Role] = useState(null);
  const [testimonial3Company, setTestimonial3Company] = useState(null);
  const [testimonial3CompanyLogo, setTestimonial3CompanyLogo] = useState(null);

  const [testimonial4Quote, setTestimonial4Quote] = useState(null);
  const [testimonial4Author, setTestimonial4Author] = useState(null);
  const [testimonial4Role, setTestimonial4Role] = useState(null);
  const [testimonial4Company, setTestimonial4Company] = useState(null);
  const [testimonial4CompanyLogo, setTestimonial4CompanyLogo] = useState(null);

  const [editingPage] = useMutation(ADMIN_UPDATED_LANDING, {
    onError(err) {
      console.log("Errrosss", err);
    },
  });

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    update(_, { data }){
      // console.log("🚀", data);
      setTimeout(() => {
        refetch();
        toast.success("Successfully Uploaded!");
      }, 2500);
    },
    onError: (err) =>{
      // console.log(err);
      // toast.error("Something went wrong💥");
      setTimeout(() => {
        toast.error(err.message);
      }, 1000);
      
    } 
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // Batch all ScrollTrigger animations with longer durations
    ScrollTrigger.batch(".feature-card", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          y: 60,
          opacity: 0,
          stagger: 0.3,
          duration: 1.6,
          ease: "power2.out"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".stat-item", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          scale: 0.5,
          opacity: 0,
          stagger: 0.2,
          duration: 2,
          ease: "elastic.out(1, 0.5)"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".testimonial-card", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          x: -50,
          opacity: 0,
          stagger: 0.2,
          duration: 1.6,
          ease: "power3.out"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".use-case-card", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          y: 60,
          opacity: 0,
          stagger: 0.3,
          duration: 1.6,
          ease: "power2.out"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".interactive-feature", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          x: -50,
          opacity: 0,
          stagger: 0.3,
          duration: 1.6,
          ease: "back.out(1.2)"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".social-box", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          scale: 0.8,
          opacity: 0,
          stagger: 0.2,
          duration: 2,
          ease: "elastic.out(1, 0.5)"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".pricing-card", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          y: 100,
          opacity: 0,
          stagger: 0.4,
          duration: 2,
          ease: "back.out(1.7)"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".MuiAccordion-root", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          x: -50,
          opacity: 0,
          stagger: 0.2,
          duration: 1.6,
          ease: "power2.out"
        });
      },
      once: true
    });

    ScrollTrigger.batch(".cta-content", {
      start: "top bottom-=100px",
      onEnter: (elements) => {
        gsap.from(elements, {
          y: 50,
          opacity: 0,
          stagger: 0.4,
          duration: 2,
          ease: "power3.out"
        });
      },
      once: true
    });

    // Cleanup function
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column">
      <CircularProgress size={60} thickness={4} style={{ color: '#2D8CFF', marginBottom: '20px' }} />
      {/* <Typography variant="h5">Loading...</Typography> */}
    </Box>
  );
  
  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Typography variant="h5" color="error">Error loading content: {error.message}</Typography>
    </Box>
  );

  // Get the content from the GraphQL query response
  const content = data?.getUpdatedHeaders || {};
  
  const editPage = async (event) => {
    let editCheck = !edit;
    setEdit(editCheck);

    // Define default values for all fields
    const defaultValues = {
        heroTitle: "Meshedinc is making virtual interactions effortless",
        heroSubtitle: "Transform your social events with web-enabled video conferencing. Add virtual attendees, customize feeds, and create immersive experiences.",
        heroButtonText: "Start Free Trial",
        whyChooseTitle: "Why Choose MeSHeDInc",
        whyChooseSubtitle: "Experience the difference with our innovative platform",
        customEventTitle: "Custom Event Layouts",
        customEventDesc: "Create personalized event layouts with multiple stream overlays",
        virtualAttendeeTitle: "Virtual Attendees",
        virtualAttendeeDesc: "Add virtual participants to simulate real attendance",
        advancedFeedTitle: "Advanced Feed Control",
        advancedFeedDesc: "Manage multiple streams with professional overlay features",
        breakFreeTitle: "Break Free from Traditional Video Calls",
        breakFreeSubtitle: "Experience immersive audio and innovative features that elevate virtual meetings",
        breakFreeFeatures: [
            'Real-time collaboration tools',
            'Interactive whiteboards',
            'Screen sharing capabilities',
            'Custom room layouts'
        ],
        virtualExpTitle: "Enhanced Virtual Attendee Experience",
        virtualExpDesc: "Create immersive virtual environments where attendees can interact naturally and engage meaningfully with content and each other. Our platform enables natural conversation flows and dynamic interactions between participants.",
        virtualExpDesc2: "With multiple viewing layouts and interactive engagement tools, attendees can customize their experience to suit their preferences. Whether it's a large conference or an intimate meeting, our seamless attendee management ensures everyone stays connected and engaged.",
        hostControlsTitle: "Powerful Host Controls & Management",
        hostControlsDesc: "Take complete control of your virtual events with our comprehensive suite of host management tools and features.",
        moderationTitle: "Moderation",
        moderationDesc: "Advanced tools to manage participants and maintain event quality",
        roomControlTitle: "Room Control",
        roomControlDesc: "Flexible room assignments and layout management",
        analyticsTitle: "Analytics",
        analyticsDesc: "Real-time insights into engagement and participation",
        permissionsTitle: "Permissions",
        permissionsDesc: "Granular control over participant access and capabilities",
        testimonialTitle: "What Our Users Say",
        testimonialSubtitle: "Join thousands of satisfied users who have transformed their virtual events",
        helpfulResourcesTitle: "Helpful Resources",
        gettingStartedTitle: "Getting Started Guide",
        gettingStartedDesc: "Learn the basics of setting up your first virtual event",
        bestPracticesTitle: "Best Practices",
        bestPracticesDesc: "Tips and tricks for hosting successful virtual events",
        apiDocsTitle: "API Documentation",
        apiDocsDesc: "Technical resources for custom integrations",
        useCasesTitle: "Discover how MeSHeDInc transforms different types of online events",
        useCasesSubtitle: "Perfect for Every Virtual Gathering",
        communityTitle: "Join Our Growing Community",
        communitySubtitle: "Connect with us and thousands of event organizers",
        communityDesc: "Share your success stories and learn from others",
        pricingTitle: "Choose Your Plan",
        pricingSubtitle: "Unlimited events & up to 5 hours per duration",
        personalPlanTitle: "Personal",
        personalPlanDesc: "Perfect for individual events",
        personalPlanPrice: "Free",
        personalPlanDuration: "45 minutes per event/day",
        personalPlanFeatures: [
            'One free event per day',
            'Event setup and management',
            'Access to all 3 panes (Event, Secondary, Virtual)',
            'Chat and audio functions',
            'Host assignment capabilities',
            'Technical support access',
            'Host moderation tools'
        ],
        proPlanTitle: "Professional",
        proPlanDesc: "For advanced features & unlimited usage",
        proPlanPrice: "Custom",
        proPlanDuration: "Unlimited events & duration",
        proPlanFeatures: [
            'All Personal features included',
            'Unlimited events per day',
            'Screen sharing capabilities',
            'Maximize all panes feature',
            'Interactive Smartboard',
            'Advanced host controls',
            'Premium technical support',
            'Custom branding options'
        ],
        faqTitle: "Frequently Asked Questions",
        faqSubtitle: "Find answers to common questions about our platform",
        faqQuestion1: "What's included in the Personal plan?",
        faqAnswer1: "The Personal plan includes one free 45-minute event per day, access to all 3 panes (Event, Secondary, Virtual), chat and audio functions, and basic host controls. Perfect for individual users and small gatherings.",
        faqQuestion2: "How do I upgrade to a Professional account?",
        faqAnswer2: "You can easily upgrade to a Professional account at any time through your dashboard. We offer seamless PayPal integration for payment processing, and your upgrade will be activated immediately after payment confirmation.",
        faqQuestion3: "What are the limitations for Personal users?",
        faqAnswer3: "Personal users are limited to one 45-minute event per day. While they have access to basic features like chat and audio, advanced features such as screen sharing and interactive smartboard are reserved for Professional users.",
        faqQuestion4: "Can I host marketing events as a Personal user?",
        faqAnswer4: "Yes, Personal users can host marketing events, but these may incur additional charges. Please contact our support team for specific pricing details for marketing events.",
        ctaTitle: "Ready to Transform Your Virtual Events?",
        ctaSubtitle: "Join thousands of organizations already using MeSHeDInc",
        ctaButtonText: "Start Free Trial",
        testimonial1Quote: "MeSHeDInc transformed how we conduct our virtual events...",
        testimonial1Author: "Sarah Johnson",
        testimonial1Role: "Event Manager",
        testimonial1Company: "TechCorp Global",
        testimonial1CompanyLogo: "https://www.svgrepo.com/show/508894/microsoft.svg",
        testimonial2Quote: "The best platform for hosting interactive virtual conferences...",
        testimonial2Author: "Michael Chen",
        testimonial2Role: "Conference Organizer",
        testimonial2Company: "EventPro Solutions",
        testimonial2CompanyLogo: "https://www.svgrepo.com/show/508897/google.svg",
        testimonial3Quote: "The virtual classroom features are outstanding...",
        testimonial3Author: "Emily Rodriguez",
        testimonial3Role: "Education Director",
        testimonial3Company: "Global Learning Institute",
        testimonial3CompanyLogo: "https://www.svgrepo.com/show/508899/apple.svg",
        testimonial4Quote: "We've seen a 200% increase in attendee engagement...",
        testimonial4Author: "David Park",
        testimonial4Role: "Marketing Director",
        testimonial4Company: "Innovation Hub",
        testimonial4CompanyLogo: "https://www.svgrepo.com/show/508907/amazon.svg"
    };

    if (edit) {
      editingPage({
        variables: {
          heroTitle: heroTitle || data?.getUpdatedHeaders?.heroTitle || defaultValues.heroTitle,
          heroSubtitle: heroSubtitle || data?.getUpdatedHeaders?.heroSubtitle || defaultValues.heroSubtitle,
          heroButtonText: heroButtonText || data?.getUpdatedHeaders?.heroButtonText || defaultValues.heroButtonText,
          whyChooseTitle: whyChooseTitle || data?.getUpdatedHeaders?.whyChooseTitle || defaultValues.whyChooseTitle,
          whyChooseSubtitle: whyChooseSubtitle || data?.getUpdatedHeaders?.whyChooseSubtitle || defaultValues.whyChooseSubtitle,
          customEventTitle: customEventTitle || data?.getUpdatedHeaders?.customEventTitle || defaultValues.customEventTitle,
          customEventDesc: customEventDesc || data?.getUpdatedHeaders?.customEventDesc || defaultValues.customEventDesc,
          virtualAttendeeTitle: virtualAttendeeTitle || data?.getUpdatedHeaders?.virtualAttendeeTitle || defaultValues.virtualAttendeeTitle,
          virtualAttendeeDesc: virtualAttendeeDesc || data?.getUpdatedHeaders?.virtualAttendeeDesc || defaultValues.virtualAttendeeDesc,
          advancedFeedTitle: advancedFeedTitle || data?.getUpdatedHeaders?.advancedFeedTitle || defaultValues.advancedFeedTitle,
          advancedFeedDesc: advancedFeedDesc || data?.getUpdatedHeaders?.advancedFeedDesc || defaultValues.advancedFeedDesc,
          breakFreeTitle: breakFreeTitle || data?.getUpdatedHeaders?.breakFreeTitle || defaultValues.breakFreeTitle,
          breakFreeSubtitle: breakFreeSubtitle || data?.getUpdatedHeaders?.breakFreeSubtitle || defaultValues.breakFreeSubtitle,
          breakFreeFeatures: breakFreeFeatures || data?.getUpdatedHeaders?.breakFreeFeatures || defaultValues.breakFreeFeatures,
          virtualExpTitle: virtualExpTitle || data?.getUpdatedHeaders?.virtualExpTitle || defaultValues.virtualExpTitle,
          virtualExpDesc: virtualExpDesc || data?.getUpdatedHeaders?.virtualExpDesc || defaultValues.virtualExpDesc,
          virtualExpDesc2: virtualExpDesc2 || data?.getUpdatedHeaders?.virtualExpDesc2 || defaultValues.virtualExpDesc2,
          hostControlsTitle: hostControlsTitle || data?.getUpdatedHeaders?.hostControlsTitle || defaultValues.hostControlsTitle,
          hostControlsDesc: hostControlsDesc || data?.getUpdatedHeaders?.hostControlsDesc || defaultValues.hostControlsDesc,
          moderationTitle: moderationTitle || data?.getUpdatedHeaders?.moderationTitle || defaultValues.moderationTitle,
          moderationDesc: moderationDesc || data?.getUpdatedHeaders?.moderationDesc || defaultValues.moderationDesc,
          roomControlTitle: roomControlTitle || data?.getUpdatedHeaders?.roomControlTitle || defaultValues.roomControlTitle,
          roomControlDesc: roomControlDesc || data?.getUpdatedHeaders?.roomControlDesc || defaultValues.roomControlDesc,
          analyticsTitle: analyticsTitle || data?.getUpdatedHeaders?.analyticsTitle || defaultValues.analyticsTitle,
          analyticsDesc: analyticsDesc || data?.getUpdatedHeaders?.analyticsDesc || defaultValues.analyticsDesc,
          permissionsTitle: permissionsTitle || data?.getUpdatedHeaders?.permissionsTitle || defaultValues.permissionsTitle,
          permissionsDesc: permissionsDesc || data?.getUpdatedHeaders?.permissionsDesc || defaultValues.permissionsDesc,
          testimonialTitle: testimonialTitle || data?.getUpdatedHeaders?.testimonialTitle || defaultValues.testimonialTitle,
          testimonialSubtitle: testimonialSubtitle || data?.getUpdatedHeaders?.testimonialSubtitle || defaultValues.testimonialSubtitle,
          helpfulResourcesTitle: helpfulResourcesTitle || data?.getUpdatedHeaders?.helpfulResourcesTitle || defaultValues.helpfulResourcesTitle,
          gettingStartedTitle: gettingStartedTitle || data?.getUpdatedHeaders?.gettingStartedTitle || defaultValues.gettingStartedTitle,
          gettingStartedDesc: gettingStartedDesc || data?.getUpdatedHeaders?.gettingStartedDesc || defaultValues.gettingStartedDesc,
          bestPracticesTitle: bestPracticesTitle || data?.getUpdatedHeaders?.bestPracticesTitle || defaultValues.bestPracticesTitle,
          bestPracticesDesc: bestPracticesDesc || data?.getUpdatedHeaders?.bestPracticesDesc || defaultValues.bestPracticesDesc,
          apiDocsTitle: apiDocsTitle || data?.getUpdatedHeaders?.apiDocsTitle || defaultValues.apiDocsTitle,
          apiDocsDesc: apiDocsDesc || data?.getUpdatedHeaders?.apiDocsDesc || defaultValues.apiDocsDesc,
          useCasesTitle: useCasesTitle || data?.getUpdatedHeaders?.useCasesTitle || defaultValues.useCasesTitle,
          useCasesSubtitle: useCasesSubtitle || data?.getUpdatedHeaders?.useCasesSubtitle || defaultValues.useCasesSubtitle,
          communityTitle: communityTitle || data?.getUpdatedHeaders?.communityTitle || defaultValues.communityTitle,
          communitySubtitle: communitySubtitle || data?.getUpdatedHeaders?.communitySubtitle || defaultValues.communitySubtitle,
          communityDesc: communityDesc || data?.getUpdatedHeaders?.communityDesc || defaultValues.communityDesc,
          pricingTitle: pricingTitle || data?.getUpdatedHeaders?.pricingTitle || defaultValues.pricingTitle,
          pricingSubtitle: pricingSubtitle || data?.getUpdatedHeaders?.pricingSubtitle || defaultValues.pricingSubtitle,
          personalPlanTitle: personalPlanTitle || data?.getUpdatedHeaders?.personalPlanTitle || defaultValues.personalPlanTitle,
          personalPlanDesc: personalPlanDesc || data?.getUpdatedHeaders?.personalPlanDesc || defaultValues.personalPlanDesc,
          personalPlanPrice: personalPlanPrice || data?.getUpdatedHeaders?.personalPlanPrice || defaultValues.personalPlanPrice,
          personalPlanDuration: personalPlanDuration || data?.getUpdatedHeaders?.personalPlanDuration || defaultValues.personalPlanDuration,
          personalPlanFeatures: personalPlanFeatures || data?.getUpdatedHeaders?.personalPlanFeatures || defaultValues.personalPlanFeatures,
          proPlanTitle: proPlanTitle || data?.getUpdatedHeaders?.proPlanTitle || defaultValues.proPlanTitle,
          proPlanDesc: proPlanDesc || data?.getUpdatedHeaders?.proPlanDesc || defaultValues.proPlanDesc,
          proPlanPrice: proPlanPrice || data?.getUpdatedHeaders?.proPlanPrice || defaultValues.proPlanPrice,
          proPlanDuration: proPlanDuration || data?.getUpdatedHeaders?.proPlanDuration || defaultValues.proPlanDuration,
          proPlanFeatures: proPlanFeatures || data?.getUpdatedHeaders?.proPlanFeatures || defaultValues.proPlanFeatures,
          faqTitle: faqTitle || data?.getUpdatedHeaders?.faqTitle || defaultValues.faqTitle,
          faqSubtitle: faqSubtitle || data?.getUpdatedHeaders?.faqSubtitle || defaultValues.faqSubtitle,
          faqQuestion1: faqQuestion1 || data?.getUpdatedHeaders?.faqQuestion1 || defaultValues.faqQuestion1,
          faqAnswer1: faqAnswer1 || data?.getUpdatedHeaders?.faqAnswer1 || defaultValues.faqAnswer1,
          faqQuestion2: faqQuestion2 || data?.getUpdatedHeaders?.faqQuestion2 || defaultValues.faqQuestion2,
          faqAnswer2: faqAnswer2 || data?.getUpdatedHeaders?.faqAnswer2 || defaultValues.faqAnswer2,
          faqQuestion3: faqQuestion3 || data?.getUpdatedHeaders?.faqQuestion3 || defaultValues.faqQuestion3,
          faqAnswer3: faqAnswer3 || data?.getUpdatedHeaders?.faqAnswer3 || defaultValues.faqAnswer3,
          faqQuestion4: faqQuestion4 || data?.getUpdatedHeaders?.faqQuestion4 || defaultValues.faqQuestion4,
          faqAnswer4: faqAnswer4 || data?.getUpdatedHeaders?.faqAnswer4 || defaultValues.faqAnswer4,
          ctaTitle: ctaTitle || data?.getUpdatedHeaders?.ctaTitle || defaultValues.ctaTitle,
          ctaSubtitle: ctaSubtitle || data?.getUpdatedHeaders?.ctaSubtitle || defaultValues.ctaSubtitle,
          ctaButtonText: ctaButtonText || data?.getUpdatedHeaders?.ctaButtonText || defaultValues.ctaButtonText,
          testimonial1Quote: testimonial1Quote || data?.getUpdatedHeaders?.testimonial1Quote || defaultValues.testimonial1Quote,
          testimonial1Author: testimonial1Author || data?.getUpdatedHeaders?.testimonial1Author || defaultValues.testimonial1Author,
          testimonial1Role: testimonial1Role || data?.getUpdatedHeaders?.testimonial1Role || defaultValues.testimonial1Role,
          testimonial1Company: testimonial1Company || data?.getUpdatedHeaders?.testimonial1Company || defaultValues.testimonial1Company,
          testimonial1CompanyLogo: testimonial1CompanyLogo || data?.getUpdatedHeaders?.testimonial1CompanyLogo || defaultValues.testimonial1CompanyLogo,
          testimonial2Quote: testimonial2Quote || data?.getUpdatedHeaders?.testimonial2Quote || defaultValues.testimonial2Quote,
          testimonial2Author: testimonial2Author || data?.getUpdatedHeaders?.testimonial2Author || defaultValues.testimonial2Author,
          testimonial2Role: testimonial2Role || data?.getUpdatedHeaders?.testimonial2Role || defaultValues.testimonial2Role,
          testimonial2Company: testimonial2Company || data?.getUpdatedHeaders?.testimonial2Company || defaultValues.testimonial2Company,
          testimonial2CompanyLogo: testimonial2CompanyLogo || data?.getUpdatedHeaders?.testimonial2CompanyLogo || defaultValues.testimonial2CompanyLogo,
          testimonial3Quote: testimonial3Quote || data?.getUpdatedHeaders?.testimonial3Quote || defaultValues.testimonial3Quote,
          testimonial3Author: testimonial3Author || data?.getUpdatedHeaders?.testimonial3Author || defaultValues.testimonial3Author,
          testimonial3Role: testimonial3Role || data?.getUpdatedHeaders?.testimonial3Role || defaultValues.testimonial3Role,
          testimonial3Company: testimonial3Company || data?.getUpdatedHeaders?.testimonial3Company || defaultValues.testimonial3Company,
          testimonial3CompanyLogo: testimonial3CompanyLogo || data?.getUpdatedHeaders?.testimonial3CompanyLogo || defaultValues.testimonial3CompanyLogo,
          testimonial4Quote: testimonial4Quote || data?.getUpdatedHeaders?.testimonial4Quote || defaultValues.testimonial4Quote,
          testimonial4Author: testimonial4Author || data?.getUpdatedHeaders?.testimonial4Author || defaultValues.testimonial4Author,
          testimonial4Role: testimonial4Role || data?.getUpdatedHeaders?.testimonial4Role || defaultValues.testimonial4Role,
          testimonial4Company: testimonial4Company || data?.getUpdatedHeaders?.testimonial4Company || defaultValues.testimonial4Company,
          testimonial4CompanyLogo: testimonial4CompanyLogo || data?.getUpdatedHeaders?.testimonial4CompanyLogo || defaultValues.testimonial4CompanyLogo
        }
      });
      toast.success("Successfully Edited!");
    }

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const imgaeHandler = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    uploadFile({ variables: { file } });
    notify();
  };

  const notify = () =>
    toast.loading("Uploading...", {
      duration: 2000,
      position: "top-center",
      icon: "💆‍♂️",
    });


  return (
    <div className={classes.root}>
      <Grid item
          container
          gap={2}
          direction="column"
          justifyContent="space-evenly"
          xs={3}
          style={{ paddingBottom: "10px" }}>
      <Grid item container justifyContent="flex-end" style={{}}>
            {edit ? (
              <Button // EDIT BUTTON
                component={Link}
                to={"#"}
                variant="contained"
                style={{
                  borderRadius: "0px",
                  background: "linear-gradient(90deg,#A15AF6,#A309D9)",
                  color: "white",
                  minWidth: "7em",

                  marginBottom: "3%",
                }}
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>
        <Grid container justifyContent="flex-end" style={{ padding: '1rem' }}>
          <Grid item>
            <Button
              variant="contained"
              style={{
                borderRadius: "0px",
                background: "linear-gradient(90deg,#A15AF6,#A309D9)",
                color: "white",
                minWidth: "7em",
              }}
              onClick={editPage}
            >
              {edit ? "Save" : "Edit"}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Hero Section */}
      <Box className={classes.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} md={6} className="hero-content">
              {
                edit ? (
                  <div className="">
                  <input
                    style={{
                      fontSize: "32px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    placeholder={data && data?.getUpdatedHeaders?.heroTitle}
                    value={
                      heroTitle
                        ? heroTitle
                        : heroTitle === null
                        ? data && data?.getUpdatedHeaders?.heroTitle
                        : ""
                    }
                    onChange={(event) => {
                      setHeroTitle(event.target.value);
                    }}
                  />
                </div>
                ) : (

                  <Typography variant="h3" className={classes.mainHeading}>
                  {data?.getUpdatedHeaders?.heroTitle || "Meshedinc is making virtual interactions effortless"}
                  </Typography>
                )
              }
              {edit ? (
                <div className="">
                <input
                  style={{
                    fontSize: "32px",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  placeholder={data && data?.getUpdatedHeaders?.heroSubtitle}
                  value={
                    heroSubtitle
                      ? heroSubtitle
                      : heroSubtitle === null
                      ? data && data?.getUpdatedHeaders?.heroSubtitle
                      : ""
                  }
                  onChange={(event) => {
                    setHeroSubtitle(event.target.value);
                  }}
                />
              </div>
              ) : (

                
                <Typography variant="h6" className={classes.subHeading}>
                {content.heroSubtitle || "Transform your social events with web-enabled video conferencing. Add virtual attendees, customize feeds, and create immersive experiences."}
                </Typography>
              )
              }
              <Button variant="contained" className={classes.heroButton}>
                {content.heroButtonText || "Start Free Trial"}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} className="hero-content">
              <img src={banner} alt="Platform Preview" style={{ width: '100%', borderRadius: 16 }} />
            </Grid>
          </Grid>

          {/* Trusted By Section */}
          <Container maxWidth="lg">
            <Box className={classes.trustedBy}>
              {/* <Typography variant="subtitle1" align="center" gutterBottom>
                Trusted by leading organizations
              </Typography> */}
              <Grid container spacing={4} justifyContent="center">
                {/* Add your client logos here */}
              </Grid>
            </Box>
          </Container>

        </Container>
      </Box>

      {/* Hero Section */}
      <Box className={classes.heroVideoSection}>
        <Container maxWidth="lg">
          <Typography variant="h3" align="center" className={classes.mainHeading} gutterBottom>
            Experience Seamless Virtual Events
              </Typography>
          <Typography variant="h6" align="center" className={classes.subHeading} gutterBottom>
            Professional-grade streaming platform for your virtual events and meetings
              </Typography>
          {/* Video Section - Now part of hero section */}
          <Box mt={8} style={{ borderRadius: 16, overflow: 'hidden' }}>
            <video 
              autoPlay 
              loop 
              muted 
              playsInline
              style={{ 
                width: '100%', 
                display: 'block',
                borderRadius: 16,
              }}
            >
              <source
                src={heroVideo}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Container>
      </Box>


      {/* Features Section */}
      <Container maxWidth="lg">
        <Box my={8}>
          {edit ? (
            <EditableInput
              edit={edit}
              value={whyChooseTitle}
              defaultValue={data?.getUpdatedHeaders?.whyChooseTitle}
              onChange={setWhyChooseTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: "center" }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom className="feature-card">
              {content.whyChooseTitle || "Why Choose MeSHeDInc"}
            </Typography>
          )}
          <Grid container spacing={4} style={{ marginTop: 40 }}>
            <Grid item xs={12} md={4} className="feature-card">
              <div className={classes.customCard}>
                <img src={Custom} alt="Custom Events" className={classes.featureIcon} />
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={customEventTitle}
                    defaultValue={data?.getUpdatedHeaders?.customEventTitle}
                    onChange={setCustomEventTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.customEventTitle || "Custom Event Layouts"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={customEventDesc}
                    defaultValue={data?.getUpdatedHeaders?.customEventDesc}
                    onChange={setCustomEventDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.customEventDesc || "Create personalized event layouts with multiple stream overlays"}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4} className="feature-card">
              <div className={classes.customCard}>
                <img src={Virtual} alt="Virtual Attendees" className={classes.featureIcon} />
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={virtualAttendeeTitle}
                    defaultValue={data?.getUpdatedHeaders?.virtualAttendeeTitle}
                    onChange={setVirtualAttendeeTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.virtualAttendeeTitle || "Virtual Attendees"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={virtualAttendeeDesc}
                    defaultValue={data?.getUpdatedHeaders?.virtualAttendeeDesc}
                    onChange={setVirtualAttendeeDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.virtualAttendeeDesc || "Add virtual participants to simulate real attendance"}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4} className="feature-card">
              <div className={classes.customCard}>
                <img src={VIP} alt="Advanced Features" className={classes.featureIcon} />
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={advancedFeedTitle}
                    defaultValue={data?.getUpdatedHeaders?.advancedFeedTitle}
                    onChange={setAdvancedFeedTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.advancedFeedTitle || "Advanced Feed Control"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={advancedFeedDesc}
                    defaultValue={data?.getUpdatedHeaders?.advancedFeedDesc}
                    onChange={setAdvancedFeedDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.advancedFeedDesc || "Manage multiple streams with professional overlay features"}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Interactive Features Section */}
      <Box style={{ padding: '4rem 0', background: '#fff' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              {edit ? (
                <EditableInput
                  edit={edit}
                  value={breakFreeTitle}
                  defaultValue={data?.getUpdatedHeaders?.breakFreeTitle}
                  onChange={setBreakFreeTitle}
                  style={{ fontSize: "32px", fontWeight: "bold" }}
                />
              ) : (
                <Typography variant="h4" gutterBottom className="interactive-feature">
                  {content.breakFreeTitle || "Break Free from Traditional Video Calls"}
                </Typography>
              )}
              {edit ? (
                <EditableInput
                  edit={edit}
                  value={breakFreeSubtitle}
                  defaultValue={data?.getUpdatedHeaders?.breakFreeSubtitle}
                  onChange={setBreakFreeSubtitle}
                  multiline
                />
              ) : (
                <Typography variant="body1" paragraph className="interactive-feature">
                  {content.breakFreeSubtitle || "Experience immersive audio and innovative features that elevate virtual meetings"}
                </Typography>
              )}
              <Grid container spacing={2}>
                {(content.breakFreeFeatures || [
                  'Real-time collaboration tools',
                  'Interactive whiteboards',
                  'Screen sharing capabilities',
                  'Custom room layouts'
                ]).map((feature, index) => (
                  <Grid item xs={12} key={index} className="interactive-feature">
                    <Box display="flex" alignItems="center">
                      <Box
                        width={20}
                        height={20}
                        bgcolor="#2D8CFF"
                        borderRadius="50%"
                        mr={2}
                      />
                      {edit ? (
                        <EditableInput
                          edit={edit}
                          value={breakFreeFeatures?.[index]}
                          defaultValue={feature}
                          onChange={(value) => {
                            const newFeatures = [...(breakFreeFeatures || content.breakFreeFeatures || [])];
                            newFeatures[index] = value;
                            setBreakFreeFeatures(newFeatures);
                          }}
                        />
                      ) : (
                        <Typography>{feature}</Typography>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className="interactive-feature">
            <video
                src={video1}
                alt="Interactive Features"
                style={{ width: '100%', borderRadius: 16 }}
                autoPlay
                loop
                muted
                playsInline
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Virtual Attendee Experience Section */}
      <Box style={{ padding: '4rem 0', background: '#f8f9fa' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
            <video
                src={video2}
                alt="Virtual Attendee Experience"
                style={{ width: '100%', borderRadius: 16 }}
                autoPlay
                loop
                muted
                playsInline
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={virtualExpTitle}
                    defaultValue={data?.getUpdatedHeaders?.virtualExpTitle}
                    onChange={setVirtualExpTitle}
                    style={{ fontSize: "32px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h4" gutterBottom>
                    {content.virtualExpTitle || "Enhanced Virtual Attendee Experience"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={virtualExpDesc}
                    defaultValue={data?.getUpdatedHeaders?.virtualExpDesc}
                    onChange={setVirtualExpDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph>
                    {content.virtualExpDesc || "Create immersive virtual environments where attendees can interact naturally and engage meaningfully with content and each other. Our platform enables natural conversation flows and dynamic interactions between participants."}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={virtualExpDesc2}
                    defaultValue={data?.getUpdatedHeaders?.virtualExpDesc2}
                    onChange={setVirtualExpDesc2}
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph>
                    {content.virtualExpDesc2 || "With multiple viewing layouts and interactive engagement tools, attendees can customize their experience to suit their preferences. Whether it's a large conference or an intimate meeting, our seamless attendee management ensures everyone stays connected and engaged."}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Host Controls Section */}
      <Box style={{ padding: '4rem 0', background: '#fff' }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              {edit ? (
                <EditableInput
                  edit={edit}
                  value={hostControlsTitle}
                  defaultValue={data?.getUpdatedHeaders?.hostControlsTitle}
                  onChange={setHostControlsTitle}
                  style={{ fontSize: "32px", fontWeight: "bold" }}
                />
              ) : (
                <Typography variant="h4" gutterBottom>
                  {content.hostControlsTitle || "Powerful Host Controls & Management"}
                </Typography>
              )}
              {edit ? (
                <EditableInput
                  edit={edit}
                  value={hostControlsDesc}
                  defaultValue={data?.getUpdatedHeaders?.hostControlsDesc}
                  onChange={setHostControlsDesc}
                  multiline
                />
              ) : (
                <Typography variant="body1" paragraph>
                  {content.hostControlsDesc || "Take complete control of your virtual events with our comprehensive suite of host management tools and features."}
                </Typography>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.customCard}>
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={moderationTitle}
                        defaultValue={data?.getUpdatedHeaders?.moderationTitle}
                        onChange={setModerationTitle}
                        style={{ fontSize: "20px", fontWeight: "bold", color: '#2D8CFF' }}
                      />
                    ) : (
                      <Typography variant="h6" gutterBottom style={{ color: '#2D8CFF' }}>
                        {content.moderationTitle || "Moderation"}
                      </Typography>
                    )}
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={moderationDesc}
                        defaultValue={data?.getUpdatedHeaders?.moderationDesc}
                        onChange={setModerationDesc}
                        multiline
                      />
                    ) : (
                      <Typography variant="body2">
                        {content.moderationDesc || "Advanced tools to manage participants and maintain event quality"}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.customCard}>
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={roomControlTitle}
                        defaultValue={data?.getUpdatedHeaders?.roomControlTitle}
                        onChange={setRoomControlTitle}
                        style={{ fontSize: "20px", fontWeight: "bold", color: '#2D8CFF' }}
                      />
                    ) : (
                      <Typography variant="h6" gutterBottom style={{ color: '#2D8CFF' }}>
                        {content.roomControlTitle || "Room Control"}
                      </Typography>
                    )}
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={roomControlDesc}
                        defaultValue={data?.getUpdatedHeaders?.roomControlDesc}
                        onChange={setRoomControlDesc}
                        multiline
                      />
                    ) : (
                      <Typography variant="body2">
                        {content.roomControlDesc || "Flexible room assignments and layout management"}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.customCard}>
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={analyticsTitle}
                        defaultValue={data?.getUpdatedHeaders?.analyticsTitle}
                        onChange={setAnalyticsTitle}
                        style={{ fontSize: "20px", fontWeight: "bold", color: '#2D8CFF' }}
                      />
                    ) : (
                      <Typography variant="h6" gutterBottom style={{ color: '#2D8CFF' }}>
                        {content.analyticsTitle || "Analytics"}
                      </Typography>
                    )}
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={analyticsDesc}
                        defaultValue={data?.getUpdatedHeaders?.analyticsDesc}
                        onChange={setAnalyticsDesc}
                        multiline
                      />
                    ) : (
                      <Typography variant="body2">
                        {content.analyticsDesc || "Real-time insights into engagement and participation"}
                      </Typography>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.customCard}>
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={permissionsTitle}
                        defaultValue={data?.getUpdatedHeaders?.permissionsTitle}
                        onChange={setPermissionsTitle}
                        style={{ fontSize: "20px", fontWeight: "bold", color: '#2D8CFF' }}
                      />
                    ) : (
                      <Typography variant="h6" gutterBottom style={{ color: '#2D8CFF' }}>
                        {content.permissionsTitle || "Permissions"}
                      </Typography>
                    )}
                    {edit ? (
                      <EditableInput
                        edit={edit}
                        value={permissionsDesc}
                        defaultValue={data?.getUpdatedHeaders?.permissionsDesc}
                        onChange={setPermissionsDesc}
                        multiline
                      />
                    ) : (
                      <Typography variant="body2">
                        {content.permissionsDesc || "Granular control over participant access and capabilities"}
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
            <video
                src={video3}
                alt="Virtual Attendee Experience"

                style={{ width: '100%', borderRadius: 16 }}
                autoPlay
                loop
                muted
                playsInline
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonial Section */}
      <Box className={classes.testimonialSection}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom style={{ color: '#fff' }}>
            {edit ? (
              <EditableInput
                edit={edit}
                value={testimonialTitle}
                defaultValue={data?.getUpdatedHeaders?.testimonialTitle}
                onChange={setTestimonialTitle}
                style={{ fontSize: "32px", fontWeight: "bold", color: '#000', textAlign: 'center' }}
              />
            ) : (
              content.testimonialTitle || "What Our Users Say"
            )}
          </Typography>
          <Typography variant="subtitle1" align="center" style={{ marginBottom: '3rem', color: 'rgba(255, 255, 255, 0.9)' }}>
            {edit ? (
              <EditableInput
                edit={edit}
                value={testimonialSubtitle}
                defaultValue={data?.getUpdatedHeaders?.testimonialSubtitle}
                onChange={setTestimonialSubtitle}
                style={{ color: '#000', textAlign: 'center' }}
              />
            ) : (
              content.testimonialSubtitle || "Join thousands of satisfied users who have transformed their virtual events"
            )}
          </Typography>
          
          <Grid container spacing={4}>
            {/* Testimonial 1 */}
            <Grid item xs={12} sm={6} md={6} lg={3} className="testimonial-card">
              <div className={classes.testimonialCard}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                  <img
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=128"
                    alt="Testimonial 1"
                    className={classes.testimonialAvatar}
                  />
                  <Box className={classes.testimonialRating}>
                    {[...Array(5)].map((_, i) => (
                      <span key={i} style={{ color: '#FFD700' }}>★</span>
                    ))}
                  </Box>
                </Box>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={testimonial1Quote}
                    defaultValue={data?.getUpdatedHeaders?.testimonial1Quote}
                    onChange={setTestimonial1Quote}
                    style={{ color: '#000' }}
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph className={classes.testimonialQuote}>
                    "{content.testimonial1Quote || "MeSHeDInc transformed how we conduct our virtual events..."}"
                  </Typography>
                )}
                <Box mt="auto">
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial1Author}
                      defaultValue={data?.getUpdatedHeaders?.testimonial1Author}
                      onChange={setTestimonial1Author}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      {content.testimonial1Author || "Sarah Johnson"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial1Role}
                      defaultValue={data?.getUpdatedHeaders?.testimonial1Role}
                      onChange={setTestimonial1Role}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial1Role || "Event Manager"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial1Company}
                      defaultValue={data?.getUpdatedHeaders?.testimonial1Company}
                      onChange={setTestimonial1Company}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial1Company || "TechCorp Global"}
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>

            {/* Testimonial 2 */}
            <Grid item xs={12} sm={6} md={6} lg={3} className="testimonial-card">
              <div className={classes.testimonialCard}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                  <img
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=crop&w=128"
                    alt="Testimonial 2"
                    className={classes.testimonialAvatar}
                  />
                  <Box className={classes.testimonialRating}>
                    {[...Array(5)].map((_, i) => (
                      <span key={i} style={{ color: '#FFD700' }}>★</span>
                    ))}
                  </Box>
                </Box>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={testimonial2Quote}
                    defaultValue={data?.getUpdatedHeaders?.testimonial2Quote}
                    onChange={setTestimonial2Quote}
                    style={{ color: '#000' }}
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph className={classes.testimonialQuote}>
                    "{content.testimonial2Quote || "The best platform for hosting interactive virtual conferences..."}"
                  </Typography>
                )}
                <Box mt="auto">
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial2Author}
                      defaultValue={data?.getUpdatedHeaders?.testimonial2Author}
                      onChange={setTestimonial2Author}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      {content.testimonial2Author || "Michael Chen"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial2Role}
                      defaultValue={data?.getUpdatedHeaders?.testimonial2Role}
                      onChange={setTestimonial2Role}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial2Role || "Conference Organizer"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial2Company}
                      defaultValue={data?.getUpdatedHeaders?.testimonial2Company}
                      onChange={setTestimonial2Company}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial2Company || "EventPro Solutions"}
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>

            {/* Testimonial 3 */}
            <Grid item xs={12} sm={6} md={6} lg={3} className="testimonial-card">
              <div className={classes.testimonialCard}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                  <img
                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=128"
                    alt="Testimonial 3"
                    className={classes.testimonialAvatar}
                  />
                  <Box className={classes.testimonialRating}>
                    {[...Array(5)].map((_, i) => (
                      <span key={i} style={{ color: '#FFD700' }}>★</span>
                    ))}
                  </Box>
                </Box>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={testimonial3Quote}
                    defaultValue={data?.getUpdatedHeaders?.testimonial3Quote}
                    onChange={setTestimonial3Quote}
                    style={{ color: '#000' }} 
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph className={classes.testimonialQuote}>
                    "{content.testimonial3Quote || "The virtual classroom features are outstanding..."}"
                  </Typography>
                )}
                <Box mt="auto">
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial3Author}
                      defaultValue={data?.getUpdatedHeaders?.testimonial3Author}
                      onChange={setTestimonial3Author}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      {content.testimonial3Author || "Emily Rodriguez"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial3Role}
                      defaultValue={data?.getUpdatedHeaders?.testimonial3Role}
                      onChange={setTestimonial3Role}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial3Role || "Education Director"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial3Company}
                      defaultValue={data?.getUpdatedHeaders?.testimonial3Company}
                      onChange={setTestimonial3Company}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial3Company || "Global Learning Institute"}
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>

            {/* Testimonial 4 */}
            <Grid item xs={12} sm={6} md={6} lg={3} className="testimonial-card">
              <div className={classes.testimonialCard}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                  <img
                    src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=128"
                    alt="Testimonial 4"
                    className={classes.testimonialAvatar}
                  />
                  <Box className={classes.testimonialRating}>
                    {[...Array(5)].map((_, i) => (
                      <span key={i} style={{ color: '#FFD700' }}>★</span>
                    ))}
                  </Box>
                </Box>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={testimonial4Quote}
                    defaultValue={data?.getUpdatedHeaders?.testimonial4Quote}
                    onChange={setTestimonial4Quote}
                    style={{ color: '#000' }}
                    multiline
                  />
                ) : (
                  <Typography variant="body1" paragraph className={classes.testimonialQuote}>
                    "{content.testimonial4Quote || "We've seen a 200% increase in attendee engagement..."}"
                  </Typography>
                )}
                <Box mt="auto">
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial4Author}
                      defaultValue={data?.getUpdatedHeaders?.testimonial4Author}
                      onChange={setTestimonial4Author}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" gutterBottom>
                      {content.testimonial4Author || "David Park"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial4Role}
                      defaultValue={data?.getUpdatedHeaders?.testimonial4Role}
                      onChange={setTestimonial4Role}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial4Role || "Marketing Director"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={testimonial4Company}
                      defaultValue={data?.getUpdatedHeaders?.testimonial4Company}
                      onChange={setTestimonial4Company}
                      style={{ color: '#000' }}
                    />
                  ) : (
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {content.testimonial4Company || "Innovation Hub"}
                    </Typography>
                  )}
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Help Section */}
      <Container maxWidth="lg">
        <Box my={8}>
          {edit ? (
            <EditableInput
              edit={edit}
              value={helpfulResourcesTitle}
              defaultValue={data?.getUpdatedHeaders?.helpfulResourcesTitle}
              onChange={setHelpfulResourcesTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: 'center' }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom>
              {content.helpfulResourcesTitle || "Helpful Resources"}
            </Typography>
          )}
          <Grid container spacing={4} style={{ marginTop: 40 }}>
            <Grid item xs={12} md={4}>
              <div className={classes.customCard}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={gettingStartedTitle}
                    defaultValue={data?.getUpdatedHeaders?.gettingStartedTitle}
                    onChange={setGettingStartedTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.gettingStartedTitle || "Getting Started Guide"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={gettingStartedDesc}
                    defaultValue={data?.getUpdatedHeaders?.gettingStartedDesc}
                    onChange={setGettingStartedDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.gettingStartedDesc || "Learn the basics of setting up your first virtual event"}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.customCard}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={bestPracticesTitle}
                    defaultValue={data?.getUpdatedHeaders?.bestPracticesTitle}
                    onChange={setBestPracticesTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.bestPracticesTitle || "Best Practices"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={bestPracticesDesc}
                    defaultValue={data?.getUpdatedHeaders?.bestPracticesDesc}
                    onChange={setBestPracticesDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.bestPracticesDesc || "Tips and tricks for hosting successful virtual events"}
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.customCard}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={apiDocsTitle}
                    defaultValue={data?.getUpdatedHeaders?.apiDocsTitle}
                    onChange={setApiDocsTitle}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6" gutterBottom>
                    {content.apiDocsTitle || "API Documentation"}
                  </Typography>
                )}
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={apiDocsDesc}
                    defaultValue={data?.getUpdatedHeaders?.apiDocsDesc}
                    onChange={setApiDocsDesc}
                    multiline
                  />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {content.apiDocsDesc || "Technical resources for custom integrations"}
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Use Cases Section */}
      <Box className={classes.useCaseSection}>
        <Container maxWidth="lg">
          {edit ? (
            <EditableInput
              edit={edit}
              value={useCasesTitle}
              defaultValue={data?.getUpdatedHeaders?.useCasesTitle}
              onChange={setUseCasesTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: 'center' }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom>
              {content.useCasesTitle || "Discover how MeSHeDInc transforms different types of online events"}
            </Typography>
          )}
          {edit ? (
            <EditableInput
              edit={edit}
              value={useCasesSubtitle}
              defaultValue={data?.getUpdatedHeaders?.useCasesSubtitle}
              onChange={setUseCasesSubtitle}
              style={{ textAlign: 'center', marginBottom: '3rem' }}
            />
          ) : (
            <Typography variant="subtitle1" align="center" style={{ marginBottom: '3rem' }}>
              {content.useCasesSubtitle || "Perfect for Every Virtual Gathering"}
            </Typography>
          )}
        </Container>
      </Box>

      {/* Community Section */}
      <Box className={classes.communitySection}>
        <Container maxWidth="lg">
          <Typography variant="h4" align="center" gutterBottom>
            Join Our Growing Community
          </Typography>
          <Typography variant="subtitle1" align="center" paragraph>
            Connect with us and thousands of event organizers across social platforms
          </Typography>
          
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Box textAlign="center" mb={4}>
                <Typography variant="body1" color="textSecondary">
                  Follow us for the latest updates, event hosting tips, and success stories
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" flexWrap="wrap">
                {[
                  {
                    icon: 'https://www.svgrepo.com/show/303114/facebook-3-logo.svg',
                    label: '50K+ Followers',
                    url: 'https://www.facebook.com/Meshedinc/',
                    platform: 'Facebook'
                  },
                  {
                    icon: 'https://www.svgrepo.com/show/303115/twitter-3-logo.svg',
                    label: '25K+ Followers',
                    url: 'https://x.com/meshedinc',
                    platform: 'Twitter'
                  },
                  {
                    icon: 'https://www.svgrepo.com/show/303145/instagram-2-1-logo.svg',
                    label: '30K+ Community',
                    url: 'https://www.instagram.com/meshedinc/',
                    platform: 'Instagram'
                  },
                  {
                    icon: 'https://www.svgrepo.com/show/303299/linkedin-icon-2-logo.svg',
                    label: '15K+ Network',
                    url: 'https://www.linkedin.com/company/meshedinc/?viewAsMember=true',
                    platform: 'LinkedIn'
                  },
                  {
                    icon: 'https://www.svgrepo.com/show/475700/youtube-color.svg',
                    label: '20K+ Subscribers',
                    url: 'https://www.youtube.com/channel/UCdvYv9YtmV7FNoiP4qsf1ew',
                    platform: 'YouTube'
                  }
                ].map((social, index) => (
                  <Box
              key={index}
                    className={classes.socialBox}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mx={3}
                    my={2}
                  >
                    <a href={social.url} target="_blank" rel="noopener noreferrer">
                    <img
                      src={social.icon}
                      alt={social.platform}
                      className={classes.socialIcon}
                    />
                    </a>
                    <Typography variant="subtitle2" style={{ color: '#2D8CFF' }} gutterBottom>
                      {social.platform}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary">
                      {social.label}
                    </Typography> */}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Box mt={6} textAlign="center">
            <Typography variant="body1" gutterBottom>
              Share your event success stories with #MeSHeDIncEvents
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Get featured on our social channels and inspire others
            </Typography>
          </Box>
        </Container>
      </Box>

      {/* Stats Section */}
      <Box className={classes.statsSection}>
        <Container maxWidth="lg">
          <Grid container spacing={4} justifyContent="center">
            {/* <Grid item xs={6} md={3} className="stat-item">
              <Typography variant="h3" className={classes.statValue} align="center">24/7</Typography>
              <Typography variant="subtitle1" align="center">Support</Typography>
            </Grid> */}
            <Grid item xs={6} md={3} className="stat-item">
              <Typography variant="h3" className={classes.statValue} align="center">100+</Typography>
              <Typography variant="subtitle1" align="center">Events per day</Typography>
            </Grid>
            <Grid item xs={6} md={3} className="stat-item">
              <Typography variant="h3" className={classes.statValue} align="center">95%</Typography>
              <Typography variant="subtitle1" align="center">Satisfaction Rate</Typography>
            </Grid>
            <Grid item xs={6} md={3} className="stat-item">
              <Typography variant="h3" className={classes.statValue} align="center">99%</Typography>
              <Typography variant="subtitle1" align="center">Uptime</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Pricing Section */}
      <Box className={classes.pricingSection}>
        <Container maxWidth="lg">
          {edit ? (
            <EditableInput
              edit={edit}
              value={pricingTitle}
              defaultValue={data?.getUpdatedHeaders?.pricingTitle}
              onChange={setPricingTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: 'center' }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom>
              {content.pricingTitle || "Choose Your Plan"}
            </Typography>
          )}
          {edit ? (
            <EditableInput
              edit={edit}
              value={pricingSubtitle}
              defaultValue={data?.getUpdatedHeaders?.pricingSubtitle}
              onChange={setPricingSubtitle}
              style={{ textAlign: 'center', marginBottom: '3rem' }}
            />
          ) : (
            <Typography variant="subtitle1" align="center" style={{ marginBottom: '3rem' }}>
              {content.pricingSubtitle || "Unlimited events & up to 5 hours per duration"}
            </Typography>
          )}
          
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6} className="pricing-card">
              <div className={classes.customCard}>
                <div className={classes.pricingHeader}>
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={personalPlanTitle}
                      defaultValue={data?.getUpdatedHeaders?.personalPlanTitle}
                      onChange={setPersonalPlanTitle}
                      style={{ fontSize: "24px", fontWeight: "bold", color: '#000', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="h5" align="center" gutterBottom>
                      {content.personalPlanTitle || "Personal"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={personalPlanDesc}
                      defaultValue={data?.getUpdatedHeaders?.personalPlanDesc}
                      onChange={setPersonalPlanDesc}
                      style={{ color: '#000', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center">
                      {content.personalPlanDesc || "Perfect for individual events"}
                    </Typography>
                  )}
                </div>
                <div className={classes.pricingContent}>
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={personalPlanPrice}
                      defaultValue={data?.getUpdatedHeaders?.personalPlanPrice}
                      onChange={setPersonalPlanPrice}
                      style={{ fontSize: "32px", fontWeight: "bold", color: '#2D8CFF', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="h3" align="center" className={classes.priceTag}>
                      {content.personalPlanPrice || "Free"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={personalPlanDuration}
                      defaultValue={data?.getUpdatedHeaders?.personalPlanDuration}
                      onChange={setPersonalPlanDuration}
                      style={{ color: 'textSecondary', textAlign: 'center', marginBottom: '24px' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center" gutterBottom color="textSecondary">
                      {content.personalPlanDuration || "45 minutes per event/day"}
                    </Typography>
                  )}
                  <Divider style={{ margin: '24px 0' }} />
                  <Box>
                    {(content.personalPlanFeatures || [
                      'One free event per day',
                      'Event setup and management',
                      'Access to all 3 panes (Event, Secondary, Virtual)',
                      'Chat and audio functions',
                      'Host assignment capabilities',
                      'Technical support access',
                      'Host moderation tools'
                    ]).map((feature, index) => (
                      <Box key={index} className={classes.pricingFeature}>
                        <Box
                          width={20}
                          height={20}
                          bgcolor="#2D8CFF"
                          borderRadius="50%"
                          mr={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="white"
                          fontSize="small"
                        >
                          ✓
                        </Box>
                        {edit ? (
                          <EditableInput
                            edit={edit}
                            value={personalPlanFeatures?.[index]}
                            defaultValue={feature}
                            onChange={(value) => {
                              const newFeatures = [...(personalPlanFeatures || content.personalPlanFeatures || [])];
                              newFeatures[index] = value;
                              setPersonalPlanFeatures(newFeatures);
                            }}
                          />
                        ) : (
                          <Typography variant="body1">{feature}</Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6} className="pricing-card">
              <div className={classes.customCard}>
                <div className={classes.pricingHeader}>
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={proPlanTitle}
                      defaultValue={data?.getUpdatedHeaders?.proPlanTitle}
                      onChange={setProPlanTitle}
                      style={{ fontSize: "24px", fontWeight: "bold", color: '#000', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="h5" align="center" gutterBottom>
                      {content.proPlanTitle || "Professional"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={proPlanDesc}
                      defaultValue={data?.getUpdatedHeaders?.proPlanDesc}
                      onChange={setProPlanDesc}
                      style={{ color: '#000', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center">
                      {content.proPlanDesc || "For advanced features & unlimited usage"}
                    </Typography>
                  )}
                </div>
                <div className={classes.pricingContent}>
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={proPlanPrice}
                      defaultValue={data?.getUpdatedHeaders?.proPlanPrice}
                      onChange={setProPlanPrice}
                      style={{ fontSize: "32px", fontWeight: "bold", color: '#2D8CFF', textAlign: 'center' }}
                    />
                  ) : (
                    <Typography variant="h3" align="center" className={classes.priceTag}>
                      {content.proPlanPrice || "Custom"}
                    </Typography>
                  )}
                  {edit ? (
                    <EditableInput
                      edit={edit}
                      value={proPlanDuration}
                      defaultValue={data?.getUpdatedHeaders?.proPlanDuration}
                      onChange={setProPlanDuration}
                      style={{ color: 'textSecondary', textAlign: 'center', marginBottom: '24px' }}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center" gutterBottom color="textSecondary">
                      {content.proPlanDuration || "Unlimited events & duration"}
                    </Typography>
                  )}
                  <Divider style={{ margin: '24px 0' }} />
                  <Box>
                    {(content.proPlanFeatures || [
                      'All Personal features included',
                      'Unlimited events per day',
                      'Screen sharing capabilities',
                      'Maximize all panes feature',
                      'Interactive Smartboard',
                      'Advanced host controls',
                      'Premium technical support',
                      'Custom branding options'
                    ]).map((feature, index) => (
                      <Box key={index} className={classes.pricingFeature}>
                        <Box
                          width={20}
                          height={20}
                          bgcolor="#2D8CFF"
                          borderRadius="50%"
                          mr={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="white"
                          fontSize="small"
                        >
                          ✓
                        </Box>
                        {edit ? (
                          <EditableInput
                            edit={edit}
                            value={proPlanFeatures?.[index]}
                            defaultValue={feature}
                            onChange={(value) => {
                              const newFeatures = [...(proPlanFeatures || content.proPlanFeatures || [])];
                              newFeatures[index] = value;
                              setProPlanFeatures(newFeatures);
                            }}
                          />
                        ) : (
                          <Typography variant="body1">{feature}</Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* FAQ Section */}
      <Box className={classes.faqSection}>
        <Container maxWidth="lg">
          {edit ? (
            <EditableInput
              edit={edit}
              value={faqTitle}
              defaultValue={data?.getUpdatedHeaders?.faqTitle}
              onChange={setFaqTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: 'center' }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom>
              {content.faqTitle || "Frequently Asked Questions"}
            </Typography>
          )}
          {edit ? (
            <EditableInput
              edit={edit}
              value={faqSubtitle}
              defaultValue={data?.getUpdatedHeaders?.faqSubtitle}
              onChange={setFaqSubtitle}
              style={{ textAlign: 'center', marginBottom: '3rem' }}
            />
          ) : (
            <Typography variant="subtitle1" align="center" style={{ marginBottom: '3rem' }}>
              {content.faqSubtitle || "Find answers to common questions about our platform"}
            </Typography>
          )}

          <Box className={classes.faqContainer}>
            {/* FAQ 1 */}
              <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
                className={classes.accordion}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                  className={classes.accordionSummary}
                >
                  {edit ? (
                    <EditableInput
                      edit={edit}
                    value={faqQuestion1}
                    defaultValue={data?.getUpdatedHeaders?.faqQuestion1}
                    onChange={setFaqQuestion1}
                      style={{ fontSize: "20px", fontWeight: "bold" }}
                    />
                  ) : (
                    <Typography variant="h6">
                    {content.faqQuestion1 || "What's included in the Personal plan?"}
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {edit ? (
                    <EditableInput
                      edit={edit}
                    value={faqAnswer1}
                    defaultValue={data?.getUpdatedHeaders?.faqAnswer1}
                    onChange={setFaqAnswer1}
                      multiline
                    />
                  ) : (
                    <Typography variant="body1">
                    {content.faqAnswer1 || "The Personal plan includes one free 45-minute event per day, access to all 3 panes (Event, Secondary, Virtual), chat and audio functions, and basic host controls. Perfect for individual users and small gatherings."}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>

            {/* FAQ 2 */}
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              className={classes.accordion}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                className={classes.accordionSummary}
              >
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqQuestion2}
                    defaultValue={data?.getUpdatedHeaders?.faqQuestion2}
                    onChange={setFaqQuestion2}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6">
                    {content.faqQuestion2 || "How do I upgrade to a Professional account?"}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqAnswer2}
                    defaultValue={data?.getUpdatedHeaders?.faqAnswer2}
                    onChange={setFaqAnswer2}
                    multiline
                  />
                ) : (
                  <Typography variant="body1">
                    {content.faqAnswer2 || "You can easily upgrade to a Professional account at any time through your dashboard. We offer seamless PayPal integration for payment processing, and your upgrade will be activated immediately after payment confirmation."}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>

            {/* FAQ 3 */}
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
              className={classes.accordion}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                className={classes.accordionSummary}
              >
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqQuestion3}
                    defaultValue={data?.getUpdatedHeaders?.faqQuestion3}
                    onChange={setFaqQuestion3}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6">
                    {content.faqQuestion3 || "What are the limitations for Personal users?"}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqAnswer3}
                    defaultValue={data?.getUpdatedHeaders?.faqAnswer3}
                    onChange={setFaqAnswer3}
                    multiline
                  />
                ) : (
                  <Typography variant="body1">
                    {content.faqAnswer3 || "Personal users are limited to one 45-minute event per day. While they have access to basic features like chat and audio, advanced features such as screen sharing and interactive smartboard are reserved for Professional users."}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>

            {/* FAQ 4 */}
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
              className={classes.accordion}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                className={classes.accordionSummary}
              >
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqQuestion4}
                    defaultValue={data?.getUpdatedHeaders?.faqQuestion4}
                    onChange={setFaqQuestion4}
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  />
                ) : (
                  <Typography variant="h6">
                    {content.faqQuestion4 || "Can I host marketing events as a Personal user?"}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                {edit ? (
                  <EditableInput
                    edit={edit}
                    value={faqAnswer4}
                    defaultValue={data?.getUpdatedHeaders?.faqAnswer4}
                    onChange={setFaqAnswer4}
                    multiline
                  />
                ) : (
                  <Typography variant="body1">
                    {content.faqAnswer4 || "Yes, Personal users can host marketing events, but these may incur additional charges. Please contact our support team for specific pricing details for marketing events."}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>
      </Box>

      {/* CTA Section */}
      <Box className={classes.ctaSection}>
        <Container maxWidth="md">
          {edit ? (
            <EditableInput
              edit={edit}
              value={ctaTitle}
              defaultValue={data?.getUpdatedHeaders?.ctaTitle}
              onChange={setCtaTitle}
              style={{ fontSize: "32px", fontWeight: "bold", textAlign: 'center' }}
            />
          ) : (
            <Typography variant="h4" align="center" gutterBottom className="cta-content">
              {content.ctaTitle || "Ready to Transform Your Virtual Events?"}
            </Typography>
          )}
          {edit ? (
            <EditableInput
              edit={edit}
              value={ctaSubtitle}
              defaultValue={data?.getUpdatedHeaders?.ctaSubtitle}
              onChange={setCtaSubtitle}
              style={{ textAlign: 'center', marginBottom: '2rem' }}
            />
          ) : (
            <Typography variant="subtitle1" align="center" paragraph className="cta-content">
              {content.ctaSubtitle || "Join thousands of organizations already using MeSHeDInc"}
            </Typography>
          )}
          <Box display="flex" justifyContent="center" mt={4} className="cta-content">
            {edit ? (
              <EditableInput
                edit={edit}
                value={ctaButtonText}
                defaultValue={data?.getUpdatedHeaders?.ctaButtonText}
                onChange={setCtaButtonText}
                style={{ textAlign: 'center' }}
              />
            ) : (
              <Button variant="contained" className={classes.heroButton} style={{ marginRight: '1rem' }}>
                {content.ctaButtonText || "Start Free Trial"}
              </Button>
            )}
          </Box>
        </Container>
      </Box>

      <Footer />
    </div>
  );
};

export default UpdatedLandingAdmin;