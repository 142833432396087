import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useContext , useEffect, useState} from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { AuthContext } from "../context/auth";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/Routes";
import "./HeaderSecondary.css";
import newLogo from "../assets/Meshed.png";
import newLogo1 from "../assets/logo_square_jpg_header.png";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "./Header";
// CommonJS
const Swal = require('sweetalert2');

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  logo: {
    height: "8em",
    [theme.breakpoints.down("md")]: {
      height: "7em",
    },
    [theme.breakpoints.down("xs")]: {
      height: "5.5em",
    },
  },
  logoContainer: {
    padding: "5px",
    // marginLeft: "75px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tabContainer: {
    marginLeft: "auto",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 10,
    marginLeft: "55px",
    "&:hover": {
      color: "rgba(138, 113, 255, 1)!important",
      border:"none"
    },
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    marginLeft: "50px",
    width: "120px",
    textTransform: "none",
    background: "black",
    marginRight: "60px",
    border:"3px solid rgba(126, 55, 182, 1)",
    height: "40px",
    "&:hover": {
      background: "linear-gradient(90deg, #824EB3 0%, #DA39C5 100%)",
      border:"none"
    },
  },
  menu: {
    backgroundColor: theme.palette.common.blue,
    color: "white",
    borderRadius: "0px",
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  drawerIcon: {
    height: "40px",
    width: "40px",
    color: "#fff"
  },
  drawerIconContainer: {
    marginLeft: "auto",
    marginRight: "20px",
    padding: "5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  drawer: {
    backgroundColor: "#131120",
    width: "250px",
  },
  drawerItem: {
    ...theme.typography.tab,
    color: "white",
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  drawerItemSelected: {
    "& .MuiListItemText-root": {
      opacity: 1,
    },
  },
  drawerPaper: {
    backgroundColor: "#131120",
    width: "250px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "20px 10px",
    justifyContent: "flex-end",
  },
  mobileNavContainer: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  desktopNavContainer: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  appbar: {},
}));

const EDIT_USER = gql`
  mutation EditUser($personalAccount: Boolean!) {
    editUser(personalAccount: $personalAccount) {
      id
      email
      username
      personalAccount
      token
    }
  }
`;


function HeaderSecondary(props) {
  const [path,setPath] = useState(false)
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  
  useEffect(()=>{
    if( location?.pathname?.includes("profile")){
      console.log("location>>>>>>>", location?.pathname);
      setPath(true)
    }
  },[
    location?.pathname
  ])
  console.log('Current path:', location.pathname);
  const [isPersonal,setisPersonal] = useState(false)



  const {
    loading: profileLoading,
    data: profileData,
    refetch: profileRefetch,
  } = useQuery(GET_PROFILE);
  const classes = useStyles();




  const context = useContext(AuthContext);
  let history = useHistory();


  useEffect(() => {

    const userToken = localStorage.getItem('jwtToken');
    // Only access profileData when it exists and loading is complete
    if (!profileLoading && profileData) {
      const username = profileData?.getUser[0]?.username?.split(" ")[0];
      const userObject = profileData?.getUser[0];
      console.log("userObject FROM USE EFFECT >>>>>>>", userObject);
      const accountType = profileData?.getUser[0]?.personalAccount;
      console.log("personalAccount>>>>>>>", accountType);
      setisPersonal(accountType);
    }
  }, [profileLoading, profileData]); // Add these dependencies
// Logout user 


const [editUser] = useMutation(EDIT_USER, {
  onCompleted: (data) => {
    setisPersonal(data.editUser.personalAccount);
    profileRefetch(); // Refresh the profile data
    Swal.fire(
      'Account Switched Successfully',
      `Switched to ${data.editUser.personalAccount ? 'Personal' : 'Professional'} Account`,
      'success'
    );
    setOpenDrawer(false);
  },
  onError: (error) => {
    Swal.fire(
      'Error',
      error.message || 'Something went wrong',
      'error'
    );
  }
});

const handleSwitchAccount = () => {
  editUser({
    variables: {
      personalAccount: !isPersonal // Toggle the current account type
    }
  });
};

const LogoutUser=()=>{
  Swal.fire(
    'You have been successfully logged out.',
    'Return to Homepage.',
    'success'
  ).then((result) => {
    context.logout();
    history.push("/");
    setOpenDrawer(false);
  });
  // props.history.push("/login");
}
console.log("profileData>>>>>>>", profileData);
const userToken = localStorage.getItem('jwtToken')
console.log("userToken>>>>>>>", userToken);
  const username = profileData?.getUser[0]?.username.split(" ")[0];
  const userObject = profileData?.getUser;
  console.log("userObject>>>>>>>", userObject);
  const personalAccount = profileData?.getUser[0]?.personalAccount;
  console.log('Personal Account>>>>>', personalAccount);
  const routes = [
    { name: `Switch To ${!isPersonal ? "Personal" : "Professional"} Account`, link: ROUTES.PRICING },
    ...(isPersonal ? [] : [{ name: "Pricing", link: ROUTES.PRICING }]),
    {
      name:
        context.user && context.user?.role !== "admin" ? (
          <div style={{}}>
            <AccountCircleIcon style={{ position: "relative", top: "0.2em" }} />
            {username}
          </div>
        ) : (
          "Login"
        ),
      link:
        context.user && context.user?.role !== "admin"
          ? ROUTES.PROFILE
          : ROUTES.LOGIN,
    },
  ];

  const tabs = (
    <div className={classes.desktopNavContainer}>
      <Tabs
        value={props.value}
        style={{
          marginTop: "25px",
          // marginBottom: "25px",
          display: "inline-flex"
        }}
        className={`${classes.tabContainer} header-tabs`}
        indicatorColor="primary"
      >
        {routes.map((route, index) => {
          // console.log("route>>>>>>>", route?.name.toString());
          // console.log("route>>>>>>>", typeof route.name === 'string' ? route.name : 'THIS IS OBJECT');
          // const isSwitch = route?.name?.includes("Switch");
          let isSwitch = false
          if(typeof route.name === 'string'){
            isSwitch = route?.name?.toString()?.includes("Switch");
          }
          console.log("isSwitch>>>>>>>", isSwitch);
          return isSwitch ? (path ? (
            <Tab
              key={`${route}${index}`}
              className={`${classes.tab} header-link`}
              style={{
                color: "rgba(255, 255, 255, 1)",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "bold",
                backgroundColor: "rgba(126, 55, 182, 0.2)",
                borderRadius: "50px",
                padding: "0 20px",
                minHeight: "40px",
                border: "2px solid rgba(126, 55, 182, 0.5)",
                margin: "0 10px",
                "&:hover": {
                  backgroundColor: "rgba(126, 55, 182, 0.4)",
                  border: "2px solid rgba(126, 55, 182, 1)",
                }
              }}
              onClick={handleSwitchAccount}
              label={route.name}
            />
          ):"") : (
            <Tab
              key={`${route}${index}`}
              className={`${classes.tab} header-link`}
              style={{
                color: "rgba(255, 255, 255, 1)",
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              component={Link}
              to={route.link}
              label={route.name}
            />
          );
        })}
      </Tabs>
      {context.user && context.user?.role !== "admin" ? (
        <Button
          component={Link}
          // to="/"
          variant="contained"
          color="secondary"
          className={`${classes.button} header-btn`}
          onClick={ ()=>{LogoutUser()}}
        >
          Logout
        </Button>
      ) : (
        <Button
          component={Link}
          to={ROUTES.SIGNUP}
          variant="contained"
          color="secondary"
          className={`${classes.button} header-btn`}
        >
          Sign Up
        </Button>
      )}
    </div>
  );

  const drawer = (
    <React.Fragment>
      <Drawer 
        open={openDrawer} 
        onClose={() => setOpenDrawer(false)} 
        classes={{paper: classes.drawerPaper}}
        className={classes.drawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setOpenDrawer(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <List>
          {routes.map((route, index) => {
            let isSwitch = false;
            if(typeof route.name === 'string'){
              isSwitch = route?.name?.toString()?.includes("Switch");
            }
            
            return isSwitch && path ? (
              <ListItem 
                key={`drawer-switch-${index}`} 
                divider 
                button 
                onClick={handleSwitchAccount}
                className={classes.drawerItem}
              >
                <ListItemText 
                  primary={route.name} 
                  style={{ color: "#fff" }}
                />
              </ListItem>
            ) : !isSwitch ? (
              <ListItem 
                key={`drawer-${index}`} 
                divider 
                button 
                component={Link} 
                to={route.link} 
                onClick={() => setOpenDrawer(false)}
                className={classes.drawerItem}
              >
                <ListItemText 
                  primary={typeof route.name === 'string' ? route.name : (context.user && context.user?.role !== "admin" ? `Profile (${username})` : "Login")} 
                  style={{ color: "#fff" }}
                />
              </ListItem>
            ) : null;
          })}
          <ListItem 
            divider 
            button 
            className={classes.drawerItem}
            onClick={() => {
              setOpenDrawer(false);
              if (context.user && context.user?.role !== "admin") {
                LogoutUser();
              } else {
                history.push(ROUTES.SIGNUP);
              }
            }}
          >
            <ListItemText 
              primary={context.user && context.user?.role !== "admin" ? "Logout" : "Sign Up"} 
              style={{ color: "#fff" }}
            />
          </ListItem>
        </List>
      </Drawer>
      <IconButton 
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        className={classes.appbar}
        style={{
          backgroundColor: "#59ACDE",
          boxShadow: "none",
        }}
      >
        <Toolbar style={{ background: "#2B35AF" }} disableGutters>
          <Button
            component={Link}
            to="/"
            disableRipple
            className={`${classes.logoContainer} header-logo`}
            style={{ position: "relative", right: "0.35em" }}
          >
            <img
              style={{
                height: "10em",
                width: "17em",
                display: "inline-block",
                overflow: "hidden",
              }}
              className="header-logo-image"
              src={newLogo1}
              alt="logo"
            ></img>
          </Button>
          {tabs}
          {drawer}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

const GET_PROFILE = gql`
  {
    getUser {
      role
      premiumMember
      roomsHost
      email
      username
      personalAccount
    }
  }
`;

export default HeaderSecondary;
// upwork profike pic change and description change 


