// import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import App from "./App";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import HttpsRedirect from "react-https-redirect";

const httpLink = createUploadLink({
  // http://localhost:4000/graphql  https://shadowcast-sandbox.io:4000/graphql   https://34.226.139.110:4000/graphql
  // https://www.meshedinc.com:4000/graphql

  
  uri: "https://www.meshedinc.com:4000/graphql",   // UNCOMMENT IT TO USE PROD SERVER
  // uri: "http://localhost:4000/graphql",



});

const authLink = setContext(() => {
  const token = localStorage.getItem("jwtToken");
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default (
  <ApolloProvider client={client}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </ApolloProvider>
);
