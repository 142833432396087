import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { Box, Container, Divider } from "@material-ui/core";
import { LOGIN, VIRTUALADAPTATIONS } from "../constants/Routes";
import privacyPolicy from "../../src/assets/privacy_pdf/Privacy_Policy_Load.pdf";
import TermsOfService from "../../src/assets/privacy_pdf/Terms_of_Service_Load.pdf";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#000",
    color: "#fff",
    padding: theme.spacing(4, 0),
    position: "relative",
    overflow: "hidden",
  },
  footerContent: {
    position: "relative",
    zIndex: 1,
  },
  gradientOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(45deg, rgba(45,140,255,0.1) 0%, rgba(0,0,0,0) 100%)",
    zIndex: 0,
  },
  logo: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    background: "linear-gradient(45deg, #8DC0FF 0%, #2D8CFF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  description: {
    color: "rgba(255, 255, 255, 0.7)",
    maxWidth: "300px",
    marginBottom: theme.spacing(3),
  },
  socialIcon: {
    color: "#fff",
    fontSize: "24px",
    marginRight: theme.spacing(2),
    transition: "all 0.3s ease",
    "&:hover": {
      color: "#2D8CFF",
      transform: "translateY(-3px)",
    },
  },
  link: {
    color: "rgba(255, 255, 255, 0.7)",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      color: "#2D8CFF",
      textDecoration: "none",
    },
  },
  sectionTitle: {
    color: "#fff",
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    margin: theme.spacing(4, 0),
  },
  copyright: {
    color: "rgba(255, 255, 255, 0.5)",
    textAlign: "center",
  },
  contactInfo: {
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.7)",
    marginBottom: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <div className={classes.gradientOverlay} />
      <Container maxWidth="lg" className={classes.footerContent}>
        <Grid container spacing={4}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Typography variant="h4" className={classes.logo}>
              MeSHeDInC
            </Typography>
            <p className={`${classes.description} ${classes.link}`}>
              Transform your virtual events with web-enabled video conferencing. Create immersive experiences that bring people together.
            </p>
            <Box display="flex" alignItems="center" mb={2}>
              <a href="https://www.facebook.com/Meshedinc/" target="_blank" rel="noopener noreferrer">
                <FacebookIcon className={classes.socialIcon} />
              </a>
              <a href="https://www.linkedin.com/company/meshedinc/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                <LinkedInIcon className={classes.socialIcon} />
              </a>
              <a href="https://x.com/meshedinc" target="_blank" rel="noopener noreferrer">
                <TwitterIcon className={classes.socialIcon} />
              </a>
              <a href="https://www.instagram.com/meshedinc/" target="_blank" rel="noopener noreferrer">
                <InstagramIcon className={classes.socialIcon} />
              </a>
              <a href="https://www.youtube.com/channel/UCdvYv9YtmV7FNoiP4qsf1ew" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon className={classes.socialIcon} />
              </a>
            </Box>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Quick Links
            </Typography>
            <Box display="flex" flexDirection="column">
              <Link to={LOGIN} className={classes.link}  style={{ marginBottom: "8px", }}>
                Login
              </Link>
              <Link to={VIRTUALADAPTATIONS} className={classes.link} style={{ marginBottom: "8px", }}>
                Virtual Adaptations
              </Link>
              <a href={TermsOfService} target="_blank" rel="noreferrer" className={classes.link} style={{ marginBottom: "8px", }}>
                Terms & Conditions
              </a>
              <a href={privacyPolicy} target="_blank" rel="noreferrer" className={classes.link} style={{ marginBottom: "8px", }}>
                Privacy Policy
              </a>
            </Box>
          </Grid>

          {/* Contact Info */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Contact Us
            </Typography>
            <Box display="flex" flexDirection="column">
              <a href="mailto:info@meshedinc.com" className={classes.link} style={{ marginBottom: "8px" }}>
                Email: info@meshedinc.com
              </a>
              <span  className={classes.link} style={{ marginBottom: "8px" }}>
                Support available 24/7
              </span>
              <span className={classes.link} style={{ marginBottom: "8px" }}>
                Based in United States
              </span>
            </Box>
          </Grid>
              <Typography style={{ alignSelf: "center", width: "100%", fontSize: "16px"}} variant="body2" className={classes.copyright}>
          © {currentYear} MeSHeDInC. All rights reserved.
        </Typography>
        </Grid>

        {/* <Divider className={classes.divider} /> */}
        
        {/* <Typography style={{backgroundColor: "blue"}} variant="body2" className={classes.copyright}>
          © {currentYear} MeSHeDInC. All rights reserved.
        </Typography> */}
      </Container>
    </footer>
  );
}







export default Footer;

