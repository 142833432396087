import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./landingTransitions.css";
import icon from "../../assets/arrow-up-right.png";
const Swal = require("sweetalert2");

import {
  ADMIN_Landing,
  UPLOAD_FILE,
  UPLOAD_FILEONE,
  UPLOAD_TOP_RIGHT_IMAGE,
} from "../../graphql/Mutations";
import { ADMIN_GETHEADER } from "../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import { useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import * as ROUTES from "../../constants/Routes";
// import bald from "../../assets/bald.jpeg";
import hostimage from "../../assets/hosts.png";
import Custom from "../../assets/Custom.png";
import VIP from "../../assets/VIP.png";
import Virtual from "../../assets/Virtual.png";

import "./landingTransitions.css";
import { gql } from "@apollo/client";
import { AuthContext } from "../../context/auth";
import { Box, CircularProgress, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.typography.estimate,
    borderRadius: "10px",
    marginLeft: "70px",
    width: "180px",
    textTransform: "none",

    marginRight: "60px",
    height: "40px",
    "&:hover": {
      background: "black",
      color: "white",
    },
  },

  sec2button: {
    ...theme.typography.estimate,
    borderRadius: "10px",
    width: "180px",
    textTransform: "none",
    background: "rgba(255, 255, 255, 1)",
    height: "50px",
    "&:hover": {
      background: "black",
      border: "2px solid black !important",
      color: "white",
    },
  },

  linear2: {
    background: " linear-gradient(94.64deg, #B023C2 -0.12%, #680DE4 99.52%)",
  },
  tryFreeButton: {
    borderRadius: "25px",
    width: "180px",
    textTransform: "none",
    backgroundColor: "#000",
    color: "#fff",
    fontSize: "1rem",

    height: "40px",
    "&:hover": {
      backgroundColor: theme.palette.common.lightBlack,
    },
  },
  placeholder: {
    "&::placeholder": {
      color: "white",
      opacity: 1,
    },
  },
  placeholder1: {
    "&::placeholder": {
      color: "black",
      opacity: 1,
    },
  },
}));

function LandingPage(props) {
  const classes = useStyles();
  const context = useContext(AuthContext);

  const [sectionCardArray, setSectionCardArray] = useState([0, 0, 0, 0, 0, 0]);
  const [edit, setEdit] = useState(false);
  const [header, setHeader] = useState(null);
  const [subHeader, setSubHeader] = useState(null);
  const [line1, setLine1] = useState(null);
  const [subline1, setSubLine1] = useState(null);
  const [line2, setLine2] = useState(null);
  const [subline2, setSubLine2] = useState(null);
  const [line3, setLine3] = useState(null);
  const [subline3, setSubLine3] = useState(null);
  const [subline31, setSubLine31] = useState(null);
  const [subline32, setSubLine32] = useState(null);
  const [subline33, setSubLine33] = useState(null);

  const [para1, setPara1] = useState(null);
  const [para31, setPara31] = useState(null);
  const [para32, setPara32] = useState(null);
  const [para33, setPara33] = useState(null);

  const [line4, setLine4] = useState(null);
  const [subline4, setSubLine4] = useState(null);
  const [subline5, setSubLine5] = useState(null);
  const [line5, setLine5] = useState(null);

  const [editingPage] = useMutation(ADMIN_Landing, {
    onError(err) {
      console.log("Errrosss", err);
    },
  });

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    update(_, { data }) {
      // console.log("🚀", data);
      setTimeout(() => {
        refetch();
        Swal.fire({
          icon: "success",
          html: "<b style='font-family:arial'>Successfully Uploaded!</b>",
        });
      }, 2500);
    },
    onError: (err) => {
      // toast.error("🤞Something went wrong💥");
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          html: "<b style='font-family:arial'>" + err.message + "</b>",
        });
      }, 1000);
    },
  });

  const { loading, data, refetch } = useQuery(ADMIN_GETHEADER);

  // Edit Function Admin CMS
  const editPage = async (event) => {
    let editCheck = !edit;
    setEdit(editCheck);

    let headerDefault = "Virtual Events";
    let subHeaderDefault =
      "Events brings a whole new level of interactivity to all-hands meetings, town halls and webcasts. Engage up to 50,000 attendees with immersive video, Q&A, polling and large meeting controls.";

    let line1Default = "Join as a guest";
    let subline1Default =
      "We have seen that what the future can be.Now its time to decide what will it be.";

    let line2Default = "Enjoy Unlimited Events for 0$";
    let subline2Default =
      "Create your event and invite people from all around the globe";

    let line3Default = "Why MeSHeDInC?";
    let subline3Default = "VIP Guests";

    let para1Default =
      " Share the spotlight with other VIPs and get time in the VIP space. Be a real VIP, just like you are in the physical realm.";
    let subline31Default = "Hosts";
    let para31Default =
      " Your host does more for you. You have an issue, message your host for immediate resolution.";

    let subline32Default = " Virtual Guests";
    let para32Default =
      " Our virtual guests have more feel of the event able to see from three vantage points; the marrying couple or the host of the town hall meeting as an example. You also see the other virtual and VIP guests.";

    let subline33Default = "Custom Rooms ";
    let para33Default =
      " Your host is their for you to arrange your private conversation with another guest. Connect with family and friends you have not spoken to in a while. Just message him/her.";

    let line4Default = " Upgrade your Events Experience";
    let subline4Default =
      " Delightfully easy collaboration is just a click away";

    //=========================================>
    let line5Default =
      " Sign Up Now and Host Your First Virtual Event For Free";
    let subline5Default =
      " Embark on a complimentary journey with Meshed! Join for free and relish the freedom to create one complimentary event at your convenience. Join us today! No credit card required.";
    //=======================================================>

    if (edit) {
      editingPage({
        variables: {
          header:
            header && header.length > 0
              ? header
              : data.getHeaders.header
              ? data.getHeaders.header
              : headerDefault,
          subHeader:
            subHeader && subHeader.length > 0
              ? subHeader
              : data.getHeaders.subHeader
              ? data.getHeaders.subHeader
              : subHeaderDefault,
          line1:
            line1 && line1.length > 0
              ? line1
              : data.getHeaders.line1
              ? data.getHeaders.line1
              : line1Default,
          subline1:
            subline1 && subline1.length > 0
              ? subline1
              : data.getHeaders.subline1
              ? data.getHeaders.subline1
              : subline1Default,
          line2:
            line2 && line2.length > 0
              ? line2
              : data.getHeaders.line2
              ? data.getHeaders.line2
              : line2Default,
          subline2:
            subline2 && subline2.length > 0
              ? subline2
              : data.getHeaders.subline2
              ? data.getHeaders.subline2
              : subline2Default,
          line3:
            line3 && line3.length > 0
              ? line3
              : data.getHeaders.line3
              ? data.getHeaders.line3
              : line3Default,
          subline3:
            subline3 && subline3.length > 0
              ? subline3
              : data.getHeaders.subline3
              ? data.getHeaders.subline3
              : subline3Default,
          para1:
            para1 && para1.length > 0
              ? para1
              : data.getHeaders.para1
              ? data.getHeaders.para1
              : para1Default,
          subline31:
            subline31 && subline31.length > 0
              ? subline31
              : data.getHeaders.subline31
              ? data.getHeaders.subline31
              : subline31Default,
          para31:
            para31 && para31.length > 0
              ? para31
              : data.getHeaders.para31
              ? data.getHeaders.para31
              : para31Default,
          subline32:
            subline32 && subline32.length > 0
              ? subline32
              : data.getHeaders.subline32
              ? data.getHeaders.subline32
              : subline32Default,
          para32:
            para32 && para32.length > 0
              ? para32
              : data.getHeaders.para32
              ? data.getHeaders.para32
              : para32Default,
          subline33:
            subline33 && subline33.length > 0
              ? subline33
              : data.getHeaders.subline33
              ? data.getHeaders.subline33
              : subline33Default,
          para33:
            para33 && para33.length > 0
              ? para33
              : data.getHeaders.para33
              ? data.getHeaders.para33
              : para33Default,
          line4:
            line4 && line4.length > 0
              ? line4
              : data.getHeaders.line4
              ? data.getHeaders.line4
              : line4Default,
          subline4:
            subline4 && subline4.length > 0
              ? subline4
              : data.getHeaders.subline4
              ? data.getHeaders.subline4
              : subline4Default,
          //====================================>
          line5:
            line5 && line5.length > 0
              ? line5
              : data.getHeaders.line5
              ? data.getHeaders.line5
              : line5Default,
          subline5:
            subline5 && subline5.length > 0
              ? subline5
              : data.getHeaders.subline5
              ? data.getHeaders.subline5
              : subline5Default,
          //==================================================>
        },
      });
      Swal.fire({
        icon: "success",
        html: "<b style='font-family:arial'>Successfully Edited!</b>",
      });
    }

    setTimeout(() => {
      refetch();
    }, 2000);
  };

  const imgaeHandler = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    uploadFile({ variables: { file } });
    notify();
  };

  // Upload image in Added Signup Section

  const [uploadFileSec2] = useMutation(UPLOAD_FILEONE, {
    update(_, { data }) {
      // console.log("🚀", data);
      setTimeout(() => {
        refetch();
        Swal.fire({
          icon: "success",
          html: "<b style='font-family:arial'>Successfully Uploaded!</b>",
        });
      }, 2500);
    },
    onError: (err) => {
      // setTimeout(() => {
      //   Swal.fire({
      //     icon: "error",
      //     html: "<b style='font-family:arial'>" + err.message + "</b>",
      //   });
      // }, 1000);
      console.log("Error", err);
    },
  });

  // Upload image validation in Added Signup Section
  const imgaeHandlerSec2 = async (e) => {
    const fileName = e.target.files[0];
    if (!fileName) return;
    uploadFileSec2({ variables: { fileName } });
    notify();
  };

  // Upload top right image in first Section

  const [uploadTopRightImage] = useMutation(UPLOAD_TOP_RIGHT_IMAGE, {
    update(_, { data }) {
      setTimeout(() => {
        refetch();
        Swal.fire({
          icon: "success",
          html: "<b style='font-family:arial'>Successfully Uploaded!</b>",
        });
      }, 2500);
    },
    onError: (err) => {
      // setTimeout(() => {
      //   Swal.fire({
      //     icon: "error",
      //     html: "<b style='font-family:arial'>" + err.message + "</b>",
      //   });
      // }, 1000);
      console.log("Error", err);
    },
  });

  // Upload top right image validation in first section
  const handleTopRightImage = async (e) => {
    const fileName = e.target.files[0];
    if (!fileName) return;
    // Read the file and get its dimensions

    const fileExtension = fileName.name.split(".").pop();
    // console.log("fileExtension", fileExtension);
    if (
      fileExtension == "jpeg" ||
      fileExtension == "jpg" ||
      fileExtension == "png"
    ) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const dimensions = {
            width: img.width,
            height: img.height,
          };
          // console.log("Image dimensions:", dimensions);
          if (dimensions.width == 608 && dimensions.height == 362) {
            uploadTopRightImage({ variables: { fileName } });
            notify();
          } else {
            Swal.fire({
              icon: "error",
              html: "<b style='font-family:arial'>Please upload image with a width of 608 pixels and a height of 362 pixels.</b>",
            });
          }
          // Do something with the dimensions, like displaying them in your UI
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(fileName);
      return 1;
    } else {
      // Swal.fire({
      //   icon: "error",
      //   html: "<b style='font-family:arial'>Upload images only in either JPG,JPEG or PNG format.</b>",
      // });
      console.log("Error", err);
    }
    // return 1;
  };

  const notify = () => {
    let timerInterval;
    Swal.fire({
      title: "Uploading...",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const timer = Swal.getPopup().querySelector("b");
        timerInterval = setInterval(() => {
          timer.textContent = `${Swal.getTimerLeft()}`;
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("");
      }
    });
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column">
      <CircularProgress size={60} thickness={4} style={{ color: '#2D8CFF', marginBottom: '20px' }} />
      {/* <Typography variant="h5">Loading...</Typography> */}
    </Box>
  );

  return (
    <div
      style={{
        // marginLeft: "18%",
        paddingTop: "2em",
        width: "98%",
      }}
    >
      <Grid
        container
        direction="row"
        item
        xs={12}
        style={{ marginBottom: "2em" }}
      >
        <Grid
          item
          container
          direction="column"
          xs={9}
          style={{ fontFamily: "Inter", width: "70%" }}
        >
          <Typography variant="h4" style={{ marginBottom: "0.5em" }}>
            {" "}
            CMS Panel
          </Typography>

          <Typography variant="subtitle" className="cms-text">
            {" "}
            The CMS Panel will allow you to change the descripton and images
            used in the MeSHeDInC website .
          </Typography>
        </Grid>

        <Grid
          item
          container
          gap={2}
          direction="column"
          justifyContent="space-evenly"
          xs={3}
          style={{ paddingBottom: "10px" }}
        >
          <Grid item container justifyContent="flex-end" style={{}}>
            {edit ? (
              <Button // EDIT BUTTON
                component={Link}
                to={"#"}
                variant="contained"
                style={{
                  borderRadius: "0px",
                  background: "linear-gradient(90deg,#A15AF6,#A309D9)",
                  color: "white",
                  minWidth: "7em",

                  marginBottom: "3%",
                }}
                onClick={() => setEdit(false)}
              >
                Cancel
              </Button>
            ) : null}
          </Grid>

          <Grid item justifyContent="flex-end" container style={{}}>
            <Grid item>
              {" "}
              <Button
                variant="contained"
                style={{
                  borderRadius: "0px",
                  background: "linear-gradient(90deg,#A15AF6,#A309D9)",
                  color: "white",
                  minWidth: "7em",
                }}
                onClick={editPage}
              >
                {edit ? "Save" : "Edit"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        className="hero"
        container
        justifyContent="center"
        align="center"
        item
        xs={12}
      >
        <Grid
          item
          container
          justifyContent="space-around"
          align="flex-end"
          style={{
            width: "100vw",
            minHeight: "520px",
          }}
        >
          <Grid
            item
            container
            sm={4}
            md={5}
            xl={6}
            direction="column"
            align="flex-end"
            justifyContent="center"
            style={{
              marginTop: "100px",
            }}
          >
            <Typography
              variant="h3"
              className="hero-sec1-heading-box"
              align="left"
              style={{
                color: "#fff",
                marginLeft: "70px",
              }}
            >
              {edit ? (
                <div className="loginInput2">
                  <input
                    style={{
                      fontSize: "32px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    type="email"
                    placeholder={data && data.getHeaders.header}
                    value={
                      header
                        ? header
                        : header === null
                        ? data && data.getHeaders.header
                        : ""
                    }
                    onChange={(event) => {
                      setHeader(event.target.value);
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant="h4"
                  align="left"
                  className="cms-grid1-heading"
                  style={{
                    color: "#fff",
                    // marginLeft: '70px',
                    marginBottom: "10px",
                  }}
                >
                  {data?.getHeaders?.header
                    ? data?.getHeaders?.header
                    : "Virtual Events"}
                </Typography>
              )}

              {edit ? (
                <div className="loginInput2 ">
                  <input
                    style={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    type="email"
                    placeholder={data && data.getHeaders.subHeader}
                    value={
                      subHeader
                        ? subHeader
                        : subHeader === null
                        ? data && data.getHeaders.subHeader
                        : ""
                    }
                    onChange={(event) => {
                      setSubHeader(event.target.value);
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant="body2"
                  align="left"
                  className="cms-text"
                  style={{
                    color: "#fff",
                  }}
                >
                  {(data && data.getHeaders.subHeader) ||
                    `MeSHeDInc is your social and business virtual event services firm. We, for example, make your marriage, graduation, town hall  meeting, board meeting, small sports event, picnic real and closer to your intended audience.`}
                </Typography>
              )}
            </Typography>
            <Button
              variant="contained"
              color="default"
              style={{
                marginTop: "25px",
                borderRadius: "50px",
                marginBottom: "30px",
              }}
              className={`${classes.button} cms-grid-btn`}
            >
              {context.user ? "Host an event" : "Sign Up"}
            </Button>
          </Grid>
          <Grid
            item
            container
            sm={8}
            md={7}
            xl={6}
            align="center"
            justifyContent="flex-end"
            style={{
              marginTop: "35px",
              alignSelf: "center",
              resizeMode: "cover",
            }}
          >
            <Grid
              item
              sm={11}
              style={{
                alignSelf: "center",
              }}
            >
              {data?.getHeaders?.topRightImage && <img
                src={data?.getHeaders?.topRightImage}
                alt="banner"
                style={{
                  position: "relative",
                  top: "110px",
                  right: "10px",
                  borderRadius: "10px",
                  width: "100%",
                  maxHeight: "500px",
                  maxWidth: "850px",
                  resizeMode: "cover",
                }}
              />}
              {edit ? (
                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // marginRight: "10%",
                    marginTop: "8rem",
                    marginBottom: "2rem",
                  }}
                >
                  {/* //! Input files */}
                  <Grid
                    item
                    style={{ marginBottom: "1rem", marginRight: "10px" }}
                    sm={12}
                  >
                    <Typography variant="h6" style={{ color: "#ffffff" }}>
                      Please upload image with a width of 608 pixels and a
                      height of 362 pixels.
                    </Typography>
                  </Grid>
                  <label
                    class="btn-grad1"
                    style={{
                      fontWeight: "700",
                      // marginRight: "55%",
                    }}
                  >
                    <input type="file" onChange={handleTopRightImage} />
                    Replace Image
                  </label>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Added Signup Section */}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        // justifyContent="center"
        className={`${classes.linear2} landing-sec-lastadd`}
        style={{
          minHeight: "400px",
          marginTop: "160px",
          padding: "90px 60px",
          justifyContent: "space-between",
        }}
        xs={12}
        sm={12}
      >
        <Grid item container sm={6} className="landing-sec-lastadd-imae">
          <Grid item xs={12} className="landing-sec-lastadd-imgbox">
            {data?.getHeaders?.imageOne && <img
              src={data?.getHeaders?.imageOne}
              alt="banner"
              className="landing-sec-lastadd-img"
              style={{ width: "35vw", height: "70vh", objectFit: "inherit" }}
            />}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: "10%",
                marginTop: "1rem",
              }}
            >
              {/* //! Input files */}
              {edit ? (
                <label
                  class="btn-grad1"
                  style={{
                    // boxShadow: "0 0 20px #000",
                    fontWeight: "700",
                    // marginRight: "55%",
                  }}
                >
                  <input type="file" onChange={imgaeHandlerSec2} />
                  Replace Image
                </label>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          sm={5}
          md={5}
          className="landing-sec-lastadd-box"
        >
          <Grid item sm={12} md={11} className="landing-sec-lastadd-text-wrap">
            {edit ? (
              <div className="loginInput2">
                <input
                  style={{
                    fontSize: "2.5rem",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  type="text"
                  placeholder={data && data.getHeaders.line5}
                  value={
                    line5
                      ? line5
                      : line5 === null
                      ? data && data.getHeaders.line5
                      : ""
                  }
                  onChange={(event) => {
                    setLine5(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h4"
                align="left"
                className="landing-sec-lastadd-heading"
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "700",
                  color: "#FFFFFF",
                }}
              >
                {data?.getHeaders?.line5 ||
                  "Sign Up Now and Host Your First Virtual Event For Free"}
              </Typography>
            )}
            {edit ? (
              <div
                style={{ marginTop: "2rem", marginBottom: "5rem" }}
                className="loginInput2 "
              >
                <input
                  style={{
                    fontSize: "1.25rem",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  type="text"
                  placeholder={data && data.getHeaders.subline5}
                  value={
                    subline5
                      ? subline5
                      : subline5 === null
                      ? data && data.getHeaders.subline5
                      : ""
                  }
                  onChange={(event) => {
                    setSubLine5(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="body1"
                align="left"
                className="landing-sec-lastadd-text"
                style={{
                  marginTop: "60px",
                  fontFamily: "'Inter', sans-serif",
                  color: "#FFFFFF",
                  fontWeight: "400",
                }}
              >
                {data?.getHeaders?.subline5 ||
                  "Embark on a complimentary journey with Meshed! Join for free and relish the freedom to create one complimentary event at your convenience. Join us today! No credit card required."}
              </Typography>
            )}
            <Grid
              item
              container
              justifyContent="flex-start"
              className="landing-sec-lastadd-btn"
              style={{ marginTop: "50px" }}
            >
              <Button
                component={Link}
                className={`${classes.sec2button} landing-sec2-join-btn`}
                variant="contained"
                style={{
                  border: "2px solid #fff",
                  borderRadius: 50,
                }}
              >
                Sign Up Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section1 */}
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        align="center"
        xs={12}
        style={{
          marginTop: "160px",
          marginBottom: "130px",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          container
          sm={6}
          style={
            {
              // marginRight: "30px",
            }
          }
        >
          <Grid item>
            {/* // TODO: imgae change get new image from backend */}
            { data?.getHeaders?.images &&
              <img
              class="images"
              src={data?.getHeaders?.images }
              alt="banner"
              style={{
                width: "35vw",
                resizeMode: "cover",
              }}
            />
            }
            {/* //! Input files */}
            {edit ? (
              <label
                class="btn-grad"
                style={{
                  boxShadow: "0 0 20px #000",
                  fontWeight: "700",
                  // marginRight: "55%",
                }}
              >
                <input type="file" onChange={imgaeHandler} />
                Replace Image
              </label>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          sm={5}
          style={{}}
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            style={
              {
                //  border: '1px solid black'
                // marginLeft: "10%",
              }
            }
          >
            <Typography
              variant="h4"
              align="left"
              style={{
                fontFamily: "'Inter', sans-serif",
                fontWeight: "700",
                width: "60%",
              }}
            >
              {edit ? (
                <div className="loginInput2 ">
                  <input
                    style={{
                      fontSize: "32px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    type="email"
                    placeholder={data?.getHeaders?.line1}
                    value={
                      line1
                        ? line1
                        : line1 === null
                        ? data && data.getHeaders.line1
                        : ""
                    }
                    onChange={(event) => {
                      setLine1(event.target.value);
                    }}
                  />
                </div>
              ) : (
                <Typography
                  variant="h4"
                  align="left"
                  style={{
                    color: "#000",
                    // marginLeft: '2%',

                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "700",
                  }}
                >
                  {data?.getHeaders?.line1 || "Join as a guest"}
                </Typography>
              )}
            </Typography>
            <Typography
              variant="body1"
              align="left"
              style={{
                marginTop: "20px",
                fontFamily: "'Inter', sans-serif",
                fontWeight: "400",
                width: "60%",
              }}
            >
              {edit ? (
                <div className="loginInput2 ">
                  <input
                    style={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      color: "black",
                    }}
                    type="email"
                    placeholder={data?.getHeaders?.subline1}
                    value={
                      subline1
                        ? subline1
                        : subline1 === null
                        ? data && data.getHeaders.subline1
                        : ""
                    }
                    onChange={(event) => {
                      setSubLine1(event.target.value);
                    }}
                  />
                </div>
              ) : (
                <Typography
                  // variant='h4'
                  className="cms-text"
                  align="left"
                  style={{
                    color: "#000",
                    // marginLeft: '70px',
                    marginBottom: "10px",
                    // border: '1px solid black',
                    marginBottom: "10px",
                    fontFamily: "'Inter', sans-serif",
                    fontWeight: "400",
                  }}
                >
                  {data?.getHeaders?.subline1 ||
                    "We have seen the possibility of the Jetsons becoming real people.The future is now coming alive!"}
                </Typography>
              )}
            </Typography>
            <Grid
              item
              container
              justifyContent="flex-start"
              style={{ marginTop: "50px" }}
            >
              <Button
                variant="contained"
                style={{
                  ":hover": { color: "white", background: "purple" },
                  width: "50%",
                  color: "black",
                  border: "1px solid black",
                  textTransform: "none",
                  borderRadius: "5px",
                  background: "white",
                }}
              >
                {" "}
                <Typography variant="body1"> Join an Event</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section2 */}
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={`${classes.linear2} cms-section-2`}
        style={{ height: "430px" }}
        xs={12}
      >
        <Grid item style={{ marginTop: "80px" }}>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            {edit ? (
              <div className="loginInput2 " style={{}}>
                <input
                  style={{
                    fontSize: "48px",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  type="text"
                  placeholder={data?.getHeaders?.line2}
                  value={
                    line2
                      ? line2
                      : line2 === null
                      ? data && data.getHeaders.line2
                      : ""
                  }
                  onChange={(event) => {
                    setLine2(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h2"
                align="left"
                style={{
                  color: "#fff",
                  // marginLeft: '70px',
                  marginBottom: "10px",
                  textAlign: "center",
                  // border: '1px solid black',
                }}
              >
                {data?.getHeaders?.line2 || "Enjoy Unlimited Events for 0$"}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "6px" }}>
          <Typography variant="h6" align="center" style={{ color: "white" }}>
            {edit ? (
              <div className="loginInput2 ">
                <input
                  style={{
                    fontSize: "24px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "white",
                  }}
                  type="text"
                  placeholder={data?.getHeaders?.subline2}
                  value={
                    subline2
                      ? subline2
                      : subline2 === null
                      ? data && data.getHeaders.subline2
                      : ""
                  }
                  onChange={(event) => {
                    setSubLine2(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h5"
                align="center"
                style={{
                  color: "#fff",
                  // marginLeft: '70px',
                  marginBottom: "10px",
                  // border: '1px solid black',
                }}
              >
                {data?.getHeaders?.subline2 ||
                  "Create your event and invite people from all around the globe"}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ height: "5em", paddingTop: "3em" }}
        >
          <Grid item style={{}}>
            {" "}
            <Button
              variant="contained"
              className="cms-grid-btn2"
              style={{
                "&:hover": { color: "white", background: "purple" },
                width: "100%",
                color: "black",
                textTransform: "none",

                borderRadius: "50px",
                background: "white",
              }}
            >
              <Typography
                variant="body1"
                className="cms-text"
                style={{ fontFamily: "Inter", fontWeight: 400 }}
              >
                Signup for free!
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <a style={{ color: "white" }} className="cms-text" href="">
              See all-in-one pricing
              <img
                style={{ position: "relative", top: "0.4em", width: "10%" }}
                src={icon}
              ></img>
            </a>
          </Grid>
        </Grid>
      </Grid>

      {/* Section3 */}
      <Grid
        item
        container
        sm={12}
        direction="column"
        style={{ margin: "100px 0px 80px 0px" }}
      >
        <Grid item>
          <Typography
            variant="h3"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {edit ? (
              <div className="loginInput2 ">
                <input
                  style={{
                    textAlign: "center",
                    fontSize: "48px",
                    fontFamily: "Inter",
                    fontWeight: 700,
                    color: "black",
                  }}
                  type="text"
                  placeholder={data?.getHeaders?.line3}
                  value={
                    line3
                      ? line3
                      : line3 === null
                      ? data && data.getHeaders.line3
                      : ""
                  }
                  onChange={(event) => {
                    setLine3(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h3"
                align="center"
                style={{ fontWeight: "bold" }}
              >
                {data?.getHeaders?.line3 || "Why MeSHeDInC?"}
              </Typography>
            )}
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          spacing={2}
          // alignItems="center"
          justifyContent="space-between"
          style={{
            marginTop: "70px",
            marginBottom: "70px",
            width: "92%",

            alignSelf: "center",
          }}
        >
          <Grid
            item
            container
            // justifyContent="space-between"
            sm={3}
            direction="column"
          >
            <Grid item container justifyContent="center">
              <img src={VIP} alt="VIP" style={{ width: "50%" }}></img>
            </Grid>
            <Grid item style={{ marginTop: "3rem" }}>
              <Typography align="center" variant="h5">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "24px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.subline3}
                      value={
                        subline3
                          ? subline3
                          : subline3 === null
                          ? data && data.getHeaders.subline3
                          : ""
                      }
                      onChange={(event) => {
                        setSubLine3(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    align="center"
                    className="cms-h5"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',

                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.subline3 || "VIP Guests"}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.para1}
                      value={
                        para1
                          ? para1
                          : para1 === null
                          ? data && data.getHeaders.para1
                          : ""
                      }
                      onChange={(event) => {
                        setPara1(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    align="center"
                    className="cms-text"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',
                      marginBottom: "10px",
                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.para1 ||
                      " Share the spotlight with other VIPs and get time in the VIP space. Be a real VIP, just like you are in the physical realm."}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm={3} direction="column">
            <Grid item container justifyContent="center">
              <img src={hostimage} alt="Hosts" style={{ width: "50%" }}></img>
            </Grid>
            <Grid item style={{ marginTop: "3rem" }}>
              <Typography align="center" variant="h5">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "24px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.subline31}
                      value={
                        subline31
                          ? subline31
                          : subline31 === null
                          ? data && data.getHeaders.subline31
                          : ""
                      }
                      onChange={(event) => {
                        setSubLine31(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    align="center"
                    className="cms-h5"
                    style={{
                      color: "#000",
                    }}
                  >
                    {data?.getHeaders?.subline31 || " Hosts"}
                  </Typography>
                )}
              </Typography>{" "}
            </Grid>
            <Grid item>
              {" "}
              <Typography align="center">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.para31}
                      value={
                        para31
                          ? para31
                          : para31 === null
                          ? data && data.getHeaders.para31
                          : ""
                      }
                      onChange={(event) => {
                        setPara31(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    align="center"
                    className="cms-text"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',
                      marginBottom: "10px",
                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.para31 ||
                      " Your host does more for you. You have an issue, message your host for immediate resolution."}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm={3} direction="column">
            <Grid item container justifyContent="center">
              <img src={Virtual} alt="VIP" style={{ width: "50%" }}></img>
            </Grid>
            <Grid item style={{ marginTop: "3rem" }}>
              {" "}
              <Typography align="center" variant="h5">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "24px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.subline32}
                      value={
                        subline32
                          ? subline32
                          : subline32 === null
                          ? data && data.getHeaders.subline32
                          : ""
                      }
                      onChange={(event) => {
                        setSubLine32(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    align="center"
                    className="cms-h5"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',

                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.subline32 || " Virtual Guests"}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography align="center">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.para32}
                      value={
                        para32
                          ? para32
                          : para32 === null
                          ? data && data.getHeaders.para32
                          : ""
                      }
                      onChange={(event) => {
                        setPara32(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    align="center"
                    className="cms-text"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',
                      marginBottom: "10px",
                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.para32 ||
                      " Our virtual guests have more feel of the event able to see from three vantage points; the marrying couple or the host of the town hall meeting as an example. You also see the other virtual and VIP guests."}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container sm={3} direction="column">
            <Grid item container justifyContent="center">
              <img src={Custom} alt="VIP" style={{ width: "50%" }}></img>
            </Grid>
            <Grid item style={{ marginTop: "3rem" }}>
              {" "}
              <Typography align="center" variant="h5">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "24px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.subline33}
                      value={
                        subline33
                          ? subline33
                          : subline33 === null
                          ? data && data.getHeaders.subline33
                          : ""
                      }
                      onChange={(event) => {
                        setSubLine33(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h5"
                    align="center"
                    className="cms-h5"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',

                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.subline33 || " Custom Rooms"}
                  </Typography>
                )}
              </Typography>{" "}
            </Grid>
            <Grid item>
              {" "}
              <Typography align="center">
                {edit ? (
                  <div className="loginInput2 ">
                    <input
                      style={{
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "black",
                      }}
                      type="text"
                      placeholder={data?.getHeaders?.para33}
                      value={
                        para33
                          ? para33
                          : para33 === null
                          ? data && data.getHeaders.para33
                          : ""
                      }
                      onChange={(event) => {
                        setPara33(event.target.value);
                      }}
                    />
                  </div>
                ) : (
                  <Typography
                    align="center"
                    className="cms-text"
                    style={{
                      color: "#000",
                      // marginLeft: '70px',
                      marginBottom: "10px",
                      // border: '1px solid black',
                    }}
                  >
                    {data?.getHeaders?.para33 ||
                      " Your host is their for you to arrange your private conversation with another guest. Connect with family and friends you have not spoken to in a while. Just message him/her."}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Section 4 - call to action */}
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={`${classes.linear2} cms-section-4`}
        style={{ height: "450px", marginBottom: "4em" }}
        xs={12}
      >
        <Grid item style={{ marginTop: "100px", paddingTop: "4px" }}>
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            {edit ? (
              <div className="loginInput2 ">
                <input
                  style={{
                    fontSize: "48px",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  type="text"
                  placeholder={data?.getHeaders?.line4}
                  value={
                    line4
                      ? line4
                      : line4 === null
                      ? data && data.getHeaders.line4
                      : ""
                  }
                  onChange={(event) => {
                    setLine4(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h3"
                align="left"
                style={{
                  color: "#fff",
                  // marginLeft: '70px',
                  marginBottom: "10px",
                  // border: '1px solid black',
                  textAlign: "center",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "600",
                }}
              >
                {data?.getHeaders?.line4 || " Upgrade your Events Experience"}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "10px" }}>
          <Typography variant="h6" align="left" style={{ color: "white" }}>
            {edit ? (
              <div className="loginInput2 ">
                <input
                  style={{
                    fontSize: "24px",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    color: "white",
                  }}
                  type="text"
                  placeholder={data?.getHeaders?.subline4}
                  value={
                    subline4
                      ? subline4
                      : subline4 === null
                      ? data && data.getHeaders.subline4
                      : ""
                  }
                  onChange={(event) => {
                    setSubLine4(event.target.value);
                  }}
                />
              </div>
            ) : (
              <Typography
                variant="h5"
                align="left"
                style={{
                  color: "#fff",
                  // marginLeft: '70px',
                  marginBottom: "10px",
                  // border: '1px solid black',
                }}
              >
                {data?.getHeaders?.subline4 ||
                  " Delightfully easy collaboration is just a click away"}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          style={{ height: "5em", paddingTop: "2em" }}
        >
          <Grid item>
            {" "}
            <Button
              className="cms-grid-btn2"
              variant="contained"
              style={{
                ":hover": { color: "white", background: "purple" },
                width: "100%",
                color: "black",
                textTransform: "none",

                borderRadius: "50px",
                background: "white",
              }}
            >
              <Typography
                style={{ fontFamily: "Inter", fontWeight: 400 }}
                className="cms-text"
              >
                {" "}
                Signup for free!
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <a style={{ color: "white" }} href="" className="cms-text">
              {" "}
              See all-in-one pricing
              <img
                style={{ position: "relative", top: "0.4em", width: "10%" }}
                src={icon}
              ></img>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;