import React from "react";
import "./userList.css";

import { ALLUSERADMIN_QUERY } from "../../graphql/Mutations";
import { useQuery } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "../../components/card/Card";

export default function UserList() {
  return (
    <div className="userList"
    //  style={{ marginLeft: "14.9%" }}
     >
      <Grid
        item
        container
        sm={8}
        direction="column"
        style={{
          // marginTop: '1%',
          width: "100%",
          height: "100%",
          // border: '1px solid black',
        }}
      >
        <Grid item container alignItems="center" style={{ marginLeft: "20px" }}>
          <Typography
            variant={"h4"}
            gutterBottom
            align="left"
            style={{ fontWeight: "bold" }}
            className="admin-price-heading"
          >
            Manage Pricing
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="column"
          alignItems="left"
          style={{ marginLeft: "30px", marginBottom: "5%" }}
        >
          <Typography
            variant={"body2"}
            gutterBottom
            align="left"
            className="admin-price-text"
            style={{ fontWeight: "normal" }}
          >
            - You can change the pricing of the professional plans for MeSHeDInC
            users here.
          </Typography>
          <Typography
            variant={"body2"}
            gutterBottom
            align="left"
            className="admin-price-text"
            style={{ fontWeight: "normal" }}
          >
            - To change the price , Click on{" "}
            <span style={{ fontWeight: "bold" }}>'Edit'</span> & modify the
            price
          </Typography>
          <Typography
            variant={"body2"}
            gutterBottom
            align="left"
            className="admin-price-text"
            style={{ fontWeight: "normal" }}
          >
            - Click on <span style={{ fontWeight: "bold" }}>Save</span> to
            update the price for the MeSHeDInC professional plan for all upcoming
            users.
          </Typography>
        </Grid>

        {/* include Card Component */}

        <Card />
      </Grid>
    </div>
  );
}
