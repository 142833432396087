import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import theme from "./constants/Theme";
import React, { lazy, Suspense, useContext } from "react";
import { AuthProvider } from "./context/auth";
import AuthRoute from "./util/AuthRoute";
import AdminRoute from "./util/AdminRoute";

import * as ROUTES from "./constants/Routes";
import { StateProvider } from "./StateContext";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import MainPage from "./pages/mainPage/MainPage";
import { AuthContext } from "./context/auth";

import AdminLogin from "./pages/login0/Login";
import Home from "./pages/home/Home";
import Coupon from "./pages/coupon/Coupon";
import UpdatedLandingAdmin from "./pages/admin/UpdatedLandingAdmin";

const CallPage = lazy(() => import("./pages/callPage/CallPage"));
const Login = lazy(() => import("./pages/login/Login"));
const StartEvent = lazy(() => import("./pages/startEvent/StartEvent"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const CreateEvent = lazy(() => import("./pages/createEvent/CreateEvent"));
const JoinEvent = lazy(() => import("./pages/joinEvent/JoinEvent"));
const LandingPage = lazy(() => import("./pages/landingPage/LandingPage"));
const UpdatedLanding = lazy(() => import("./pages/landingPage/UpdatedLanding"));
const NotFound = lazy(() => import("./pages/notFound/NotFound"));
const GuestJoinPage = lazy(() => import("./pages/guestJoinPage/GuestJoinPage"));
const VipGuestPage = lazy(() => import("./pages/vipGuestPage/VipGuestPage"));
const CustomFeed = lazy(() =>
  import("./pages/createCustomFeed/CreateCustomFeed")
);
const CustomJoinPage = lazy(() =>
  import("./pages/customJoinPage/CustomJoinPage")
);
const KickedPage = lazy(() => import("./pages/kickedPage/KickedPage"));
const HostsJoinPage = lazy(() => import("./pages/hostsJoinPage/HostsJoinPage"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const Pricing = lazy(() => import("./pages/Pricing/Pricing"));
const Pricing1 = lazy(() => import("./pages/Pricing1/Pricing1"));
const FORGOTPASSWORD = lazy(() =>
  import("./pages/Forgotpassword/forgotpassword")
);
const VERIFICATIONCODE = lazy(() =>
  import("./pages/Forgotpassword/verificationcode")
);
const SETNEWPASSWORD = lazy(() =>
  import("./pages/Forgotpassword/setnewpassword")
);
const RESETDONE = lazy(() => import("./pages/Forgotpassword/resetdone"));
const ShareLinkScreen = lazy(() =>
  import("./pages/shareLinkScreen/ShareLinkScreen")
);

const AdminLogin1 = lazy(() => import("./pages/login/Login"));
//<--------------------------------------------->
const FORGOTPASSWORDADMIN = lazy(() =>
  import("./pages/Forgotpasswordadmin/forgotpassword")
);
const VERIFICATIONCODEADMIN = lazy(() =>
  import("./pages/Forgotpasswordadmin/verificationcode")
);
const SETNEWPASSWORDADMIN = lazy(() =>
  import("./pages/Forgotpasswordadmin/setnewpassword")
);
const RESETDONEADMIN = lazy(() => import("./pages/Forgotpasswordadmin/resetdone"));
//<--------------------------------------------->
function App() {
  const { user } = useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <StateProvider>
          <Router>
            <Suspense
              fallback={() => {
                console.log("Loading");
              }}
            >
              <Switch>
                <Route path={ROUTES.LOGIN} component={Login} />
                <Route path={ROUTES.SIGNUP} component={Signup} />
                <Route exact path="/admin/login" component={AdminLogin} />
                {/*  */}
                <Route
                  exact
                  path={ROUTES.VIRTUALADAPTATIONS}
                  component={LandingPage}
                />
                <Route exact path={ROUTES.LANDING_PAGE} component={UpdatedLanding} />
                <Route exact path={ROUTES.JOIN_EVENT} component={JoinEvent} />
                <Route path={ROUTES.CREATE_EVENT} component={CreateEvent} />
                <Route path={ROUTES.CALL_PAGE} component={CallPage} />
                <Route path={ROUTES.GUESTJOINPAGE} component={GuestJoinPage} />
                <Route
                  exact
                  path={ROUTES.CUSTOMJOINPAGE}
                  component={CustomJoinPage}
                />
                <Route
                  path={ROUTES.VIPGUESTJOINPAGE}
                  component={VipGuestPage}
                />
                <Route path={ROUTES.KICKED_PAGE} component={KickedPage} />
                <Route path={ROUTES.PRICING} component={Pricing} />
                <Route path={ROUTES.PRICING1} component={Pricing1} />
                <Route
                  path={ROUTES.FORGOTPASSWORD}
                  component={FORGOTPASSWORD}
                />
                <Route
                  path={ROUTES.VERIFICATIONCODE}
                  component={VERIFICATIONCODE}
                />
                <Route
                  path={ROUTES.SETNEWPASSWORD}
                  component={SETNEWPASSWORD}
                />
                <Route path={ROUTES.RESETDONE} component={RESETDONE} />
                <Route path={ROUTES.CUSTOMFEED} component={CustomFeed} />

                <Route path={ROUTES.HOSTJOINPAGE} component={HostsJoinPage} />
                <Route path={ROUTES.STARTEVENT} component={StartEvent} />
                <Route
                  path={ROUTES.SHARE_LINKSCREEN}
                  component={ShareLinkScreen}
                />

                <AuthRoute
                  user={true}
                  path={ROUTES.PROFILE}
                  component={Profile}
                />
                {/* <-------------------------------------------------------------------> */}
                
                <Route
                  path={ROUTES.FORGOTPASSWORDADMIN}
                  component={FORGOTPASSWORDADMIN}
                />
                <Route
                  path={ROUTES.VERIFICATIONCODEADMIN}
                  component={VERIFICATIONCODEADMIN}
                />
                <Route
                  path={ROUTES.SETNEWPASSWORDADMIN}
                  component={SETNEWPASSWORDADMIN}
                />
                <Route path={ROUTES.RESETDONEADMIN} component={RESETDONEADMIN} />
                {/* <------------------------------------------> */}

                <AdminRoute exact path="/admin/users" component={UserList} />
                <Redirect exact from="/admin" to="/admin/login" />

                <AdminRoute exact path="/admin/mainPage" component={MainPage} />
                <AdminRoute exact path="/admin/updatedLanding" component={UpdatedLandingAdmin} />

                <AdminRoute exact path="/admin/home" component={Home} />
                <AdminRoute exact path="/admin/coupon" component={Coupon} />
              </Switch>
            </Suspense>
          </Router>
        </StateProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;



