import React, { useEffect, useState } from "react";
import "./card.css";
import { ADMIN_PRICE } from "../../graphql/Mutations";
import { ADMIN_PRICEDATA } from "../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { Grid, Card, CardActions, CardContent } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
const Card1 = () => {
  const [edit, setEdit] = useState(false);
  const [price, setPrice] = useState(null);

  const [priceChange] = useMutation(ADMIN_PRICE, {
    onCompleted: (data) => {
      // console.log("🔥", data);
      refetch();
    },
    onError: (err) => console.error(err),
  });
  const { _, data, refetch } = useQuery(ADMIN_PRICEDATA);

  const editHandler = () => {
    let editCheck = !edit;
    setEdit(editCheck);
    if (edit) {
      if (price === null) return toast.error("No input ");
      priceChange({ variables: { price: price } }); //+price
      setPrice(null);
      refetch();
    }
  };

  return (
    <div>
      {/* <div class='courses-container'>
        <div class='course'>
          <div class='course-preview'>
            <h2>Meshed Payment</h2>
          </div>
          <div class='course-info'>
            <div class='progress-container'></div>
            <h4>Amount</h4>
            {edit ? (
              <div
                className='loginInput'
                style={{ borderBottom: '2px solid #eee', margin: '20px 0;' }}
              >
                <input
                  type='text'
                  // min="1"
                  autofocus
                  placeholder={data?.priceData?.price || 399}
                  value={price}
                  onChange={(event) => {
                    setPrice(event.target.value);
                  }}
                />
                <span></span>
              </div>
            ) : (
              <h2>${data?.priceData?.price || 399}</h2>
            )}

            <button type='button' class='btn' onClick={editHandler}>
              {edit ? 'Save' : 'Edit'}
            </button>
          </div>
        </div>
      </div> */}
      <Grid
        container
        // justify='space-around'
        style={{
          // border: '2px solid black',
          paddingLeft: "2em",
        }}
      >
        {/* <Grid item>
          {' '}
          <Card
            style={{
              boxShadow: '2px 3px 10px grey',
              minWidth: '275px',
              maxWidth: '275px',
              height: '400px',
              '&:hover': {
                background:
                  'linear-gradient(94.64deg, #B023C2 -0.12%, #680DE4 99.52%   )',
                color: 'white',
              },
            }}
          >
            <CardContent>
              <Typography variant='h4'>Free</Typography>
            </CardContent>

            <CardContent>
              {' '}
              <Typography variant='h4'>
                {' '}
                <h4> $0</h4>
              </Typography>
            </CardContent>
            <CardContent>
              {' '}
              <Typography variant='body1'>
                ✓ Host up to 100 participants
              </Typography>
              <Typography variant='body1' style={{ marginTop: '5px' }}>
                ✓ Unlimited group meetings up to 40 minutes
              </Typography>
            </CardContent>

            <CardActions style={{ marginLeft: '20px', marginTop: '20px' }}>
              <Button
                style={{
                  background: 'white',
                  color: 'black',
                  position: 'relative',
                  left: '70px',
                  // ':hover': { backgroundColor: 'black', color: 'white' },
                  boxShadow: '1px 1px 3px grey',
                  marginTop: '2px',
                }}
              >
                Signup
              </Button>
            </CardActions>
          </Card>
        </Grid> */}
        <Grid item>
          <Card
            className="card-main-grid"
            style={{
              overflowY: "auto",
              boxShadow: "2px 3px 10px grey",
              minWidth: "275px",
              maxWidth: "275px",
              marginBottom:"30px",
              height: "600px",
              "&:hover": {
                background: "red",
                color: "white",
              },
            }}
          >
            <CardContent>
              <Typography variant="h4" className="card-main-heading">
              Professional
              </Typography>
            </CardContent>

            <CardContent style={{ paddingBottom: "0", paddingTop: "0" }}>
              {" "}
              <Typography variant="h6">
                {edit ? (
                  <div
                    className="loginInput"
                    style={{
                      borderBottom: "2px solid #eee",
                      margin: "20px 0;",
                    }}
                  >
                    <input
                      type="text"
                      // min="1"
                      autofocus
                      placeholder={data?.priceData?.price || 399}
                      value={price}
                      onChange={(event) => {
                        setPrice(event.target.value);
                      }}
                    />
                    <span></span>
                  </div>
                ) : (
                  <h2 className="card-main-rupee">
                    ${data?.priceData?.price || 399}
                  </h2>
                )}
              </Typography>
            </CardContent>
            <CardContent style={{ paddingTop: "0", maxHeight: "290px", overflowY: "auto" }}>
              {" "}
              {[
                "All Personal features included",
                "Unlimited events per day",
                "Screen sharing capabilities",
                "Maximize all panes feature",
                "Interactive Smartboard",
                "Advanced host controls",
                "Premium technical support",
                "Custom branding options"
              ].map((point, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  style={{ 
                    marginTop: index === 0 ? "0" : "5px",
                    fontSize: "0.85rem" 
                  }}
                  className="card-main-text"
                >
                  ✓ {point}
                </Typography>
              ))}
            </CardContent>

            <CardActions style={{ 
              marginTop: "auto", 
              display: "flex", 
              justifyContent: "center", 
              alignItems: "center",
              // position: "absolute",
              // bottom: "10px",
              // width: "100%"
            }}>
              <Button
              className="price-edit-btn"
                style={{
                  background: "white",
                  color: "black",
                  position: "relative",
                  boxShadow: "1px 1px 3px grey",
                }}
                onClick={editHandler}
              >
                {edit ? "Save" : "Edit"}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Card1;
