import { gql } from '@apollo/client';

export const UPLOAD_FILE = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      url
    }
  }
`;


export const UPLOAD_FILEONE = gql`
  mutation singleUploadFileOne($fileName: Upload!) {
    singleUploadFileOne(fileName: $fileName) {
      url
    }
  }
`;


// Upload top right image schema in first section
export const UPLOAD_TOP_RIGHT_IMAGE = gql`
  mutation UploadTopRightImage($fileName: Upload!) {
    UploadTopRightImage(fileName: $fileName) {
      url
    }
  }
`;

export const ADMIN_MUTATION = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      id
      token
      email
      username
      role
    }
  }
`;

export const ADMIN_Landing = gql`
  mutation langdingPage(
    $header: String
    $subHeader: String
    $line1: String
    $subline1: String
    $line2: String
    $subline2: String
    $line3: String
    $subline3: String
    $para1: String
    $subline31: String
    $para31: String
    $subline32: String
    $para32: String
    $subline33: String
    $para33: String
    $line4: String
    $subline4: String
    $line5: String
    $subline5: String
  ) {
    langdingPage(
      header: $header
      subHeader: $subHeader
      line1: $line1
      subline1: $subline1
      line2: $line2
      subline2: $subline2
      line3: $line3
      subline3: $subline3
      para1: $para1
      subline31: $subline31
      para31: $para31
      subline32: $subline32
      para32: $para32
      subline33: $subline33
      para33: $para33
      line4: $line4
      subline4: $subline4
      line5: $line5
      subline5: $subline5
    ) {
      header
      subHeader
      line1
      subline1
      line2
      subline2
      line3
      subline3
      para1
      subline31
      para31
      subline32
      para32
      subline33
      para33
      line4
      subline4
      line5
      subline5
    }
  }
`;

export const ADMIN_CMS = gql`
  mutation singleUpload($file: Upload!) {
    singleUpload(file: $file) {
      url
    }
  }
`;

export const ADMIN_UPLOAD = gql`
  mutation uploadfile($fileName: String) {
    uploadfile(fileName: $fileName) {
      url
    }
  }
`;

export const ADMIN_PRICE = gql`
  mutation priceChange($price: String) {
    priceChange(price: $price) {
      price
    }
  }
`;

export const ALLUSERADMIN_QUERY = gql`
  {
    allUserGet {
      username
      email
      premiumMember
      createdAt
    }
  }
`;

export const ADMIN_UPDATED_LANDING = gql`
  mutation UpdatedLandingPage(
    $heroTitle: String!
    $heroSubtitle: String!
    $heroButtonText: String!
    $whyChooseTitle: String!
    $whyChooseSubtitle: String!
    $customEventTitle: String!
    $customEventDesc: String!
    $virtualAttendeeTitle: String!
    $virtualAttendeeDesc: String!
    $advancedFeedTitle: String!
    $advancedFeedDesc: String!
    $breakFreeTitle: String!
    $breakFreeSubtitle: String!
    $breakFreeFeatures: [String!]!
    $virtualExpTitle: String!
    $virtualExpDesc: String!
    $virtualExpDesc2: String!
    $hostControlsTitle: String!
    $hostControlsDesc: String!
    $moderationTitle: String!
    $moderationDesc: String!
    $roomControlTitle: String!
    $roomControlDesc: String!
    $analyticsTitle: String!
    $analyticsDesc: String!
    $permissionsTitle: String!
    $permissionsDesc: String!
    $testimonialTitle: String!
    $testimonialSubtitle: String!
    $testimonial1Quote: String!
    $testimonial1Author: String!
    $testimonial1Role: String!
    $testimonial1Company: String!
    $testimonial1CompanyLogo: String!
    $testimonial2Quote: String!
    $testimonial2Author: String!
    $testimonial2Role: String!
    $testimonial2Company: String!
    $testimonial2CompanyLogo: String!
    $testimonial3Quote: String!
    $testimonial3Author: String!
    $testimonial3Role: String!
    $testimonial3Company: String!
    $testimonial3CompanyLogo: String!
    $testimonial4Quote: String!
    $testimonial4Author: String!
    $testimonial4Role: String!
    $testimonial4Company: String!
    $testimonial4CompanyLogo: String!
    $helpfulResourcesTitle: String!
    $gettingStartedTitle: String!
    $gettingStartedDesc: String!
    $bestPracticesTitle: String!
    $bestPracticesDesc: String!
    $apiDocsTitle: String!
    $apiDocsDesc: String!
    $useCasesTitle: String!
    $useCasesSubtitle: String!
    $communityTitle: String!
    $communitySubtitle: String!
    $communityDesc: String!
    $pricingTitle: String!
    $pricingSubtitle: String!
    $personalPlanTitle: String!
    $personalPlanDesc: String!
    $personalPlanPrice: String!
    $personalPlanDuration: String!
    $personalPlanFeatures: [String!]!
    $proPlanTitle: String!
    $proPlanDesc: String!
    $proPlanPrice: String!
    $proPlanDuration: String!
    $proPlanFeatures: [String!]!
    $faqTitle: String!
    $faqSubtitle: String!
    $faqQuestion1: String!
    $faqAnswer1: String!
    $faqQuestion2: String!
    $faqAnswer2: String!
    $faqQuestion3: String!
    $faqAnswer3: String!
    $faqQuestion4: String!
    $faqAnswer4: String!
    $ctaTitle: String!
    $ctaSubtitle: String!
    $ctaButtonText: String!
  ) {
    updatedLandingPage(
      heroTitle: $heroTitle
      heroSubtitle: $heroSubtitle
      heroButtonText: $heroButtonText
      whyChooseTitle: $whyChooseTitle
      whyChooseSubtitle: $whyChooseSubtitle
      customEventTitle: $customEventTitle
      customEventDesc: $customEventDesc
      virtualAttendeeTitle: $virtualAttendeeTitle
      virtualAttendeeDesc: $virtualAttendeeDesc
      advancedFeedTitle: $advancedFeedTitle
      advancedFeedDesc: $advancedFeedDesc
      breakFreeTitle: $breakFreeTitle
      breakFreeSubtitle: $breakFreeSubtitle
      breakFreeFeatures: $breakFreeFeatures
      virtualExpTitle: $virtualExpTitle
      virtualExpDesc: $virtualExpDesc
      virtualExpDesc2: $virtualExpDesc2
      hostControlsTitle: $hostControlsTitle
      hostControlsDesc: $hostControlsDesc
      moderationTitle: $moderationTitle
      moderationDesc: $moderationDesc
      roomControlTitle: $roomControlTitle
      roomControlDesc: $roomControlDesc
      analyticsTitle: $analyticsTitle
      analyticsDesc: $analyticsDesc
      permissionsTitle: $permissionsTitle
      permissionsDesc: $permissionsDesc
      testimonialTitle: $testimonialTitle
      testimonialSubtitle: $testimonialSubtitle
      testimonial1Quote: $testimonial1Quote
      testimonial1Author: $testimonial1Author
      testimonial1Role: $testimonial1Role
      testimonial1Company: $testimonial1Company
      testimonial1CompanyLogo: $testimonial1CompanyLogo
      testimonial2Quote: $testimonial2Quote
      testimonial2Author: $testimonial2Author
      testimonial2Role: $testimonial2Role
      testimonial2Company: $testimonial2Company
      testimonial2CompanyLogo: $testimonial2CompanyLogo
      testimonial3Quote: $testimonial3Quote
      testimonial3Author: $testimonial3Author
      testimonial3Role: $testimonial3Role
      testimonial3Company: $testimonial3Company
      testimonial3CompanyLogo: $testimonial3CompanyLogo
      testimonial4Quote: $testimonial4Quote
      testimonial4Author: $testimonial4Author
      testimonial4Role: $testimonial4Role
      testimonial4Company: $testimonial4Company
      testimonial4CompanyLogo: $testimonial4CompanyLogo
      helpfulResourcesTitle: $helpfulResourcesTitle
      gettingStartedTitle: $gettingStartedTitle
      gettingStartedDesc: $gettingStartedDesc
      bestPracticesTitle: $bestPracticesTitle
      bestPracticesDesc: $bestPracticesDesc
      apiDocsTitle: $apiDocsTitle
      apiDocsDesc: $apiDocsDesc
      useCasesTitle: $useCasesTitle
      useCasesSubtitle: $useCasesSubtitle
      communityTitle: $communityTitle
      communitySubtitle: $communitySubtitle
      communityDesc: $communityDesc
      pricingTitle: $pricingTitle
      pricingSubtitle: $pricingSubtitle
      personalPlanTitle: $personalPlanTitle
      personalPlanDesc: $personalPlanDesc
      personalPlanPrice: $personalPlanPrice
      personalPlanDuration: $personalPlanDuration
      personalPlanFeatures: $personalPlanFeatures
      proPlanTitle: $proPlanTitle
      proPlanDesc: $proPlanDesc
      proPlanPrice: $proPlanPrice
      proPlanDuration: $proPlanDuration
      proPlanFeatures: $proPlanFeatures
      faqTitle: $faqTitle
      faqSubtitle: $faqSubtitle
      faqQuestion1: $faqQuestion1
      faqAnswer1: $faqAnswer1
      faqQuestion2: $faqQuestion2
      faqAnswer2: $faqAnswer2
      faqQuestion3: $faqQuestion3
      faqAnswer3: $faqAnswer3
      faqQuestion4: $faqQuestion4
      faqAnswer4: $faqAnswer4
      ctaTitle: $ctaTitle
      ctaSubtitle: $ctaSubtitle
      ctaButtonText: $ctaButtonText
    ) {
      heroTitle
      heroSubtitle
      heroButtonText
      whyChooseTitle
      whyChooseSubtitle
      customEventTitle
      customEventDesc
      virtualAttendeeTitle
      virtualAttendeeDesc
      advancedFeedTitle
      advancedFeedDesc
      breakFreeTitle
      breakFreeSubtitle
      breakFreeFeatures
      virtualExpTitle
      virtualExpDesc
      virtualExpDesc2
      hostControlsTitle
      hostControlsDesc
      moderationTitle
      moderationDesc
      roomControlTitle
      roomControlDesc
      analyticsTitle
      analyticsDesc
      permissionsTitle
      permissionsDesc
      testimonialTitle
      testimonialSubtitle
      testimonial1Quote
      testimonial1Author
      testimonial1Role
      testimonial1Company
      testimonial1CompanyLogo
      testimonial2Quote
      testimonial2Author
      testimonial2Role
      testimonial2Company
      testimonial2CompanyLogo
      testimonial3Quote
      testimonial3Author
      testimonial3Role
      testimonial3Company
      testimonial3CompanyLogo
      testimonial4Quote
      testimonial4Author
      testimonial4Role
      testimonial4Company
      testimonial4CompanyLogo
      helpfulResourcesTitle
      gettingStartedTitle
      gettingStartedDesc
      bestPracticesTitle
      bestPracticesDesc
      apiDocsTitle
      apiDocsDesc
      useCasesTitle
      useCasesSubtitle
      communityTitle
      communitySubtitle
      communityDesc
      pricingTitle
      pricingSubtitle
      personalPlanTitle
      personalPlanDesc
      personalPlanPrice
      personalPlanDuration
      personalPlanFeatures
      proPlanTitle
      proPlanDesc
      proPlanPrice
      proPlanDuration
      proPlanFeatures
      faqTitle
      faqSubtitle
      faqQuestion1
      faqAnswer1
      faqQuestion2
      faqAnswer2
      faqQuestion3
      faqAnswer3
      faqQuestion4
      faqAnswer4
      ctaTitle
      ctaSubtitle
      ctaButtonText
    }
  }
`;



